import React from "react";
import "./DesktopHotspotPanelContent.css"
import { useDispatch, useSelector } from "react-redux";
import openButtonImage from '../../images/groupOpenButton.png';
import closeButtonImage from '../../images/groupCloseButton.png';
import { setSelectedGlobalHotspotGroupId } from "../../reducers/viewerSlice";
import GlobalHotspot from '../GlobalHotspot/GlobalHotspot'
import GlobalHotspotGroupIdentifier from "./GlobalHotspotGroupIdentifier";

export default function DesktopHotspotPanelContent() {

    const dispatch = useDispatch();
    const cmpts = useSelector(state => state.scene.componentByIds);
    const selectedGlobalHotspotGroupId = useSelector(state => state.viewer.selectedGlobalHotspotGroupId)
    const allGlobalHotspotGroupComponents = Object.keys(cmpts).filter(key => cmpts[key].type === "globalHotspotGroupComponent")

    const selectAHotspotGroup = (groupIdToBeSet) => {

        let groupIdToDispatch;
        if (selectedGlobalHotspotGroupId === groupIdToBeSet) {
            groupIdToDispatch = ""
        } else {
            groupIdToDispatch = groupIdToBeSet
        }
        dispatch(setSelectedGlobalHotspotGroupId(groupIdToDispatch))        
    }

    return (
        <div className="globalHotspotGroupDesktop">
            <div className="customize-your-product-desktop">Customize your product</div>
            <div className="groupTabDesktop">
                {allGlobalHotspotGroupComponents.map((hotspotGroupId) => (
                    <div className="contentContainer">
                        <div className="groupButtonDesktop">
                            <GlobalHotspotGroupIdentifier globalHotspotGroupData={cmpts[hotspotGroupId].data} />
                            <div className="groupDisplayName">{cmpts[hotspotGroupId].data.displayName}</div>
                            <img src={(selectedGlobalHotspotGroupId === hotspotGroupId) ? closeButtonImage : openButtonImage}
                                alt=""
                                className="openCloseGroupButton"
                                onClick={() => selectAHotspotGroup(hotspotGroupId)}
                            />
                        </div>
                        <div className={(selectedGlobalHotspotGroupId === hotspotGroupId) ? "hotspotContainerDesktop" : "hidden"}>
                            {cmpts[hotspotGroupId].data.selectedGlobalHotspots.map((globalHotspotId) => {
                                if (cmpts[globalHotspotId]) {
                                let globalHotspotData = cmpts[globalHotspotId].data
                                return (
                                    <GlobalHotspot
                                        globalHotspotId={globalHotspotId}
                                        globalHotspotData={globalHotspotData} />)
                                }
                            })
                            }
                        </div>
                    </div>
                ))}
            </div>   
        </div>
    )
}