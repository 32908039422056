import React from "react";
import {  useSelector } from "react-redux";
import "./GlobalHotspotGroupIdentifier.css"

function GlobalHotspotGroupIdentifier({ globalHotspotGroupData }){

  const assetByIds = useSelector(state => state.scene.assetByIds);
  const iconAssetId = globalHotspotGroupData.iconAssetId
  const iconImage = iconAssetId && assetByIds[iconAssetId].data.iconImage
  const color = globalHotspotGroupData.color
  const hasIconAssetUrl = iconAssetId && iconImage

  const convertToRgb = (color) => {
    return (
      "rgb(" +
      color[0] * 255 +
      "," +
      color[1] * 255 +
      "," +
      color[2] * 255 +
      ")"
    );
  };

  if (hasIconAssetUrl) {
    return (
        <div className="box" >
          <img className="image" src={iconImage} />
        </div>
    )
  } 

  if (color) {
    return (
      <div className="box">
        <div className="image" style={{ backgroundColor: convertToRgb(color) }}></div>
      </div>
    )
  }

  return (
      <div className="box" >
          <img className="image" alt="" />
      </div>
  )
}
export default GlobalHotspotGroupIdentifier