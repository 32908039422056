import { setComponent, setSelectedGlobalHotspot } from "../ecs/updateComponents";
import { getEntityById, setChild } from "../ecs/useEntities";
import { getEntity } from "../ecs/Entity";
import { vec3, quat } from "gl-matrix";
import { addAsset, createMaterialsFromMaterialAssets } from "../ecs/useAssets";
import { OgmoConsts } from "../constants/consts";
import { generatePlaneMeshAsset } from "../utils/MeshGenerator";
import { createComponent } from "../ecs/Component";
import { MaterialAsset, cloneMaterialAsset } from "../assets/MaterialAsset";
import { loadGLB } from "./GLBLoader";

const loadScene = (path, _onCompleted, _onError) => {
	// fech scene json file
	fetch(path)
		.then((response) => {
			return response.json();
		})
		.then((data) => {
			rebuildScene(data, _onCompleted);
		})
		.catch((e) => {
			_onError();
			console.log(e)
		});
}

const rebuildScene = async (data, _onCompleted) => {
	var root = data.root;
	var entities = data.entities;
	var assets = data.assets;
	var components = data.components;

	const loadAssets = async (assets) => {
		return new Promise(async (resolve, reject) => {
			for (var key in assets) {

				const asset = assets[key];
				if (asset.type === OgmoConsts.AssetType.MESH) {
					if (asset.data.meshPath) {
						let response = await fetch(asset.data.meshPath);
						let loadedAsset = await response.json();
						loadedAsset.data.meshPath = response.url;
						addAsset(loadedAsset);
					} else if (asset.data.primitiveType === OgmoConsts.MeshPrimitiveType.PLANE) {
						let mesh_asset = generatePlaneMeshAsset(1, 1, 1, 1);
						mesh_asset.id = asset.id;
						mesh_asset.name = asset.name;
						addAsset(mesh_asset);
					}
				} else if (asset.type === OgmoConsts.AssetType.GLB) {
					if (asset.data.glbPath) {
						await loadGLB(asset.data.glbPath)
						addAsset(asset);
					}
				} else {
					 addAsset(asset);
				}
			}
			resolve();
		})
	}

	const rebuildSceneGraph = (childrenIds, parent) => {
		childrenIds.forEach(childId => {
			const loadedEntity = entities[childId];
			const childEntity = getEntity();
			copyEntityData(childEntity, loadedEntity);
			//setComponents(childEntity, loadedEntity.components);
			setChild(childEntity, parent);
			rebuildSceneGraph(loadedEntity.children, childEntity);
		});
	}

	const copyEntityData = (entity, copy) => {

		entity.name = copy.name;
		entity.id = copy.id;
		vec3.copy(entity.position, copy.position);
		vec3.copy(entity.rotation, copy.rotation);
		vec3.copy(entity.scale, copy.scale);
		quat.copy(entity.quaternion, copy.quaternion ? copy.quaternion : quat.fromEuler(entity.quaternion, copy.rotation[0], copy.rotation[1], copy.rotation[2]));
		entity.selected = copy.selected;
		entity.active = copy.active;
		entity.parent = copy.parent;
	}

	const copyComponentData = (component, copy) => {
		component.type = copy.type;
		component.id = copy.id;
		component.data = { ...copy.data };
	}

	const setComponents = () => {
		for (var key in entities) {
			let entity = entities[key];
			let sceneEntity = getEntityById(key);
			entity.components.forEach(component => {
				//loadComponent(component, entity);
				let newcomponent = createComponent();
				let cmpt = components[component.id];

				if (cmpt) {
					copyComponentData(newcomponent, cmpt)
					setComponent(newcomponent, sceneEntity);
				}
			});
		}
	}

	const setDefaultGlobalHotspot = () => {
		const firstGlobalHotspotId = Object.keys(components).find(componentId => components[componentId].type === "globalHotspotComponent")
		if (firstGlobalHotspotId) {
			setSelectedGlobalHotspot(firstGlobalHotspotId)
		}
		return firstGlobalHotspotId
	}

	await loadAssets(assets);
	await createMaterialsFromMaterialAssets();
	rebuildSceneGraph(root.children, null);
	setComponents();
	const defaultGlobalHotspotId = setDefaultGlobalHotspot()
	_onCompleted(defaultGlobalHotspotId);
}

export { loadScene }
