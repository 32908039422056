import { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { shiftSnackPack, setIsSnackBarShow, setErrText } from '../reducers/snackBarSlice';

export default function UseSnackBarMessage() {

    const snackPack = useSelector(state => state.snackbar.snackPack);
    const errText = useSelector(state => state.snackbar.errText);
    const isSnackBarShow = useSelector(state => state.snackbar.isSnackBarShow);

    const dispatch = useDispatch();

    useEffect(() => {
        if (snackPack.length && !errText) {
            dispatch(setIsSnackBarShow(true));
            dispatch(setErrText({ ...snackPack[0] }));
            dispatch(shiftSnackPack());

        } else if (snackPack.length && errText && isSnackBarShow) {
            dispatch(setIsSnackBarShow(false));
        }
    }, [snackPack, errText, isSnackBarShow]);

    useEffect(() => {
        const handleCloseSnackBar = () => {
            dispatch(setIsSnackBarShow(false));
            dispatch(setErrText(null));
        };

        if (isSnackBarShow) {
            setTimeout(() => { handleCloseSnackBar(); }, 2950);
        }
    }, [isSnackBarShow]);

    return [errText, isSnackBarShow];
};