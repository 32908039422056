import React  from "react";
import baseIcon from "../../images/base_icon.png";
import "./GlobalHotspot.css"
import { useDispatch, useSelector } from "react-redux";
import { getAssetById, setSelectedGlobalHotspot } from 'ogmo_core';
import { setSelectedGlobalHotspotId } from "../../reducers/viewerSlice";

export default function ({ globalHotspotData, globalHotspotId }) {

    const selectedGlobalHotspotId = useSelector(state => state.viewer.selectedGlobalHotspotId)
    const displayName = globalHotspotData.displayName;
    const globalHotspotColor = globalHotspotData.color;
    const globalHotspotIcon = globalHotspotData.iconAssetId;
    const dispatch = useDispatch();
    const cmpts = useSelector(state => state.scene.componentByIds);
    const ents = useSelector(state => state.scene.entityByIds);

    let allMeshComponents = [];
    Object.keys(ents).forEach(entityId => {
        ents[entityId].components.map((component, id) => {
            if (component.type === "meshComponent") {
                allMeshComponents = [...allMeshComponents, cmpts[component.id]]
            }
        })
    })

    const applySelectedGlobalHotspotConfigurations = (selectedGlobalHotspotId, targetMeshMaterialOptions) => {
        dispatch(setSelectedGlobalHotspotId(selectedGlobalHotspotId));
        setSelectedGlobalHotspot(selectedGlobalHotspotId)
    }

    const convertToRgb = (color) => {
        return (
            "rgb(" +
            color[0] * 255 +
            "," +
            color[1] * 255 +
            "," +
            color[2] * 255 +
            ")"
        );
    };

    let icon;
    if (getAssetById(globalHotspotIcon)) {
        icon = getAssetById(globalHotspotIcon).data.iconImage
        return (
            <div
                className={(selectedGlobalHotspotId === globalHotspotId ? "cardSelected" : "card")}
                style={{ border: selectedGlobalHotspotId === globalHotspotId ? 'solid 2.2px #3d6acf' : 'solid 2.2px white' }}
                onClick={() => applySelectedGlobalHotspotConfigurations(globalHotspotId, globalHotspotData.targetMeshMaterialOptions)}
            >
                <img src={icon} alt="" className="baseIcon"></img>
                <div className="globalHotspotName">{displayName}</div>
            </div>
        )
    }

    if (globalHotspotColor.length) {
        return (
            <div className="card">
                <div
                    className="colorItemMobile"
                    style={{ backgroundColor: convertToRgb(globalHotspotColor), boxShadow: selectedGlobalHotspotId === globalHotspotId ? '0 0 0 2px #3d6acf' : '0 0 0 2px white' }}
                    onClick={() => applySelectedGlobalHotspotConfigurations(globalHotspotId, globalHotspotData.targetMeshMaterialOptions)}
                >
                </div>
                <div className="globalHotspotName">{displayName}</div>
            </div>
        )
    }

    return (
        <div
            className={(selectedGlobalHotspotId === globalHotspotId ? "cardSelected" : "card")}
            style={{ border: selectedGlobalHotspotId === globalHotspotId ? 'solid 2.2px #3d6acf' : 'solid 2.2px white' }}
            onClick={() => applySelectedGlobalHotspotConfigurations(globalHotspotId, globalHotspotData.targetMeshMaterialOptions)}
        >
            <img src={baseIcon} alt="" className="baseIcon"></img>
            <div className="globalHotspotName">{displayName}</div>
        </div>
    )
}

