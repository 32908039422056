import React,{useState} from 'react';
import '../GroupDesignOverlay/GroupDesignOverlay.css';
import useOuterClick from "../useOuterClick";
import { useDispatch } from "react-redux";
import { setCurrentViewerDesignId} from "../../reducers/viewerSlice";

export default function GroupDesignOverlay({designsList,groupName,isSceneLoaded}) {

    const dispatch = useDispatch();
    const [isOverlayVisible,setIsOverlayVisible] = useState(true);
    const innerRef = useOuterClick(e => {
        handleClick();
    });

    const handleClick = () => {        
        if(isOverlayVisible){
            setIsOverlayVisible(false);
        }else{            
            setIsOverlayVisible(true);
        }
    }
// TODO : active class rename as active1 due to MeshAndMaterialOptionHotSpotContainer.css also have a active class.
  const hamburgButtonClassName = isOverlayVisible ? 'ham hamRotate180 ham5':'ham hamRotate180 ham5 active1';
  const hamburgSvgClassName = isSceneLoaded === true ? 'hamburger':'';

    return (
        <>
            <div
                className={hamburgSvgClassName}
                onClick={handleClick}
                ref={isOverlayVisible === true ? null : innerRef}
            >
                {isSceneLoaded && <svg class={hamburgButtonClassName} viewBox="0 0 100 100" width="38" >
                    <path
                        class="line top"
                        d="m 30,33 h 40 c 0,0 8.5,-0.68551 8.5,10.375 0,8.292653 -6.122707,9.002293 -8.5,6.625 l -11.071429,-11.071429" />
                    <path
                        class="line middle"
                        d="m 70,50 h -40" />
                    <path
                        class="line bottom"
                        d="m 30,67 h 40 c 0,0 8.5,0.68551 8.5,-10.375 0,-8.292653 -6.122707,-9.002293 -8.5,-6.625 l -11.071429,11.071429" />
                </svg>}
            </div>
        <div className="sidebar" style={{ height: !isOverlayVisible ? '100%' : '0%' }}>
            <div className="groupName">{groupName}</div>
            <div className="list">
                {designsList.map((design, index) => {
                    return (
                        <div className="listBody" key={index}>
                            <div
                                className="listItem"
                                onClick={()=>
                                    {
                                    dispatch(setCurrentViewerDesignId(design.id));
                                    handleClick();
                                }}
                            >
                                {design.name}
                            </div>
                        </div>
                    );
                })}
            </div>
            {/* <div className="itemCount">{designsList.length + ` Items`}</div> */}
        </div>
        </>
    )
}