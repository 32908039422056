import * as THREE from 'three';
import { mat4, quat, vec3 } from "gl-matrix";
import { hasComponent, getComponent } from '../ecs/useComponets';
import { setComponentNeedsUpdate } from '../ecs/updateComponents';
import { setSelectedEntity, getEntityById, setChild, deleteEntity, getAllChildrenRecursively } from '../ecs/useEntities.js';
import { getAssetById } from '../ecs/useAssets.js';
import { createARAssetFromScene } from '../utils/CreateARAsset.js'
import { OgmoConsts } from '../constants/consts';
import { OgmoCache } from '../utils/Cache.js';
import { getSkyData } from '../components/SkyBoxComponent.js';
import { getTransform, getPosition, setPosition, setScale, setRotation, setQuaternion } from '../ecs/useTransformManager.js';
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { TransformControls } from "three/examples/jsm/controls/TransformControls";
import { RGBELoader } from 'three/examples/jsm/loaders/RGBELoader.js';
import { EffectComposer } from "three/examples/jsm/postprocessing/EffectComposer";
import { OutlinePass } from "../3rdparty/OutlinePass";
import { RenderPass } from "three/examples/jsm/postprocessing/RenderPass";
import { ShaderPass } from "three/examples/jsm/postprocessing/ShaderPass";
import { FXAAShader } from 'three/examples/jsm/shaders/FXAAShader.js';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js'
import { store } from '../reducer/storeCreator';
import { loadGLB } from '../loaders/GLBLoader';

var canvas = canvas;
var initialized = false;
var entities = {};
var helpers = {};
var materials = [];
var isViewer = true;
var eligibleForRaycast = true;
var scene,
	renderer,
	camera,
	ogmo_camera_entity,
	hdrCubeRenderTarget,
	pmremGenerator,
	controls,
	raycaster,
	objectHelper,
	transformControls,
	composer,
	outlinePass,
	effectFXAA;

const createARAsset = (uploadGLBFile) => {
	createARAssetFromScene(scene, uploadGLBFile);
}

const uninitializeRenderer = () => {
	ogmo_camera_entity = null;
	initialized = false;
	renderer.dispose();
	if(hdrCubeRenderTarget) {
		hdrCubeRenderTarget.dispose();
	}
	if(pmremGenerator) {
		pmremGenerator.dispose();
	}
}

const cleanupLoadedObjectsRenderer = () => {
	
	ogmo_camera_entity = null;

	if(scene) {
		for (let key in entities) {
			let entity = entities[key];
			scene.remove(entity); 
			if(entity.geometry) {
				entity.geometry.dispose();
			}
		}
	}
	entities = {};
}

const initializeRenderer = (_canvas, onInitialized, _isViewer = true) => {
	canvas = _canvas;
	isViewer = _isViewer;
	renderer = new THREE.WebGLRenderer({ canvas: canvas, antialias: true });

	renderer.toneMapping = THREE.ACESFilmicToneMapping;
	//renderer.gammaOutput = true;
	renderer.physicallyCorrectLights = true;
	renderer.outputEncoding = THREE.sRGBEncoding;
	renderer.shadowMap.enabled = true;
	renderer.shadowMap.autoUpdate = false;
	renderer.shadowMap.type = THREE.PCFSoftShadowMap;
	renderer.setPixelRatio(window.devicePixelRatio);

	pmremGenerator = new THREE.PMREMGenerator(renderer);
	pmremGenerator.compileEquirectangularShader();

	scene = new THREE.Scene();
	camera = new THREE.PerspectiveCamera(45, canvas.clientWidth / canvas.clientHeight, 0.1, 1000);
	controls = new OrbitControls(camera, canvas);
	controls.update();

	/* Set Default Light */
	const hemiLight = new THREE.HemisphereLight(0xffffff, 0x444444 );
	hemiLight.position.set(0, 200, 0);
	scene.add(hemiLight);

	/* Setting up post ptocessing */
	composer = new EffectComposer(renderer);

	let renderPass = new RenderPass(scene, camera);
	composer.addPass(renderPass);

	if (!isViewer) {
		outlinePass = new OutlinePass(new THREE.Vector2(canvas.clientWidth, canvas.clientHeight), scene, camera);
		outlinePass.hiddenEdgeColor = new THREE.Color(1, 1, 1);
		composer.addPass(outlinePass);
	}

	effectFXAA = new ShaderPass(FXAAShader);
	effectFXAA.uniforms['resolution'].value.set(1 / canvas.clientWidth, 1 / canvas.clientHeight);
	composer.addPass(effectFXAA);

	/* End of setting up post ptocessing */

	var makeEligibleForRaycastTimeout = null

	controls.addEventListener('change', () => {
		eligibleForRaycast = false;
	});

	controls.addEventListener('end', () => {
		clearTimeout(makeEligibleForRaycastTimeout)
		makeEligibleForRaycastTimeout = setTimeout(()=> {
			eligibleForRaycast = true;
		}, 20)
	});

	raycaster = new THREE.Raycaster();

	// Setup the items related to editor
	if (!isViewer) {
		setupEditor();
	}

	window.addEventListener('resize', resize);
	resize();

	canvas.addEventListener('click', handleRaycast);

	onInitialized();
	initialized = true;
};

const handleRaycast = (event) => {

	if (!eligibleForRaycast) {
		return;
	}

	let mouse = new THREE.Vector2();
	mouse.x = (event.offsetX / canvas.clientWidth) * 2 - 1;
	mouse.y = - (event.offsetY / canvas.clientHeight) * 2 + 1;

	raycaster.setFromCamera(mouse, camera);
	let intersections = raycaster.intersectObjects(scene.children, true);

	for (let idx = 0; idx < intersections.length; ++idx) {
		if (entities[intersections[idx].object.name]) {
			setSelectedEntity(intersections[idx].object.name);
			break;
		}
	}
}

const updateCamera = (camera_entity) => {
	if (camera_entity) {
		const camera_data = getComponent(OgmoConsts.ComponentType.CAMERA, camera_entity).data;
		const camera_pos = getPosition(camera_entity.id);
		camera.position.set(camera_pos[0], camera_pos[1], camera_pos[2]);
		camera.up = new THREE.Vector3(camera_data.up[0], camera_data.up[1], camera_data.up[2]);
		camera.lookAt(new THREE.Vector3(camera_data.center[0], camera_data.center[1], camera_data.center[2]));

		// Set camera controls params
		controls.minDistance = camera_data.minZoomDistance;
		controls.maxDistance = camera_data.maxZoomDistance;
		controls.minPolarAngle = camera_data.minPolarAngle;
		controls.maxPolarAngle = camera_data.maxPolarAngle;
		controls.zoomSpeed = camera_data.zoomSpeed;
		controls.rotateSpeed = camera_data.rotateSpeed;
		controls.dampingFactor = camera_data.dampingFactor;
		controls.enabled = camera_data.enableInteraction;
		controls.enableZoom = camera_data.enableZoom;
		controls.enableDamping = camera_data.enableDamping;
		controls.update();
	}
	return 1;
}

const updateEntitiesRenderer = async () => {

	if (!initialized) {
		return 1;
	}
	let state = store.getState();

	// Remove deleted entities from the scene
	deleteEntities(state.scene.deletedEntityIds);

	let updates = [];
	state.scene.entityIds.forEach(id => {
		let entity = getEntityById(id);
		updates = [...updates, ...updateEntityRenderer(entity)];
	})

	await Promise.all(updates);
	resize();

	renderer.shadowMap.needsUpdate = true;

	return 1;
};

const calculateWorldCenter = async () => {
	let centerWorld = null;
	let meshCount = 0;

	for (let key in entities) {
		let entity = entities[key];

		if (entity.type === "Object3D") {
			let transform = getTransform(key);

			const box = new THREE.Box3().setFromObject(entity);
			const center = box.getCenter(new THREE.Vector3());
			//center.applyMatrix4(new THREE.Matrix4().fromArray(transform.matrixWorld));

			if (centerWorld) {
				centerWorld.add(center);
			} else {
				centerWorld = center.clone();
			}
			meshCount++;
		}
	}
	if(centerWorld){
		centerWorld.divideScalar(meshCount);
		controls.target = centerWorld.clone();
	}
}

const updateEntityRenderer = (ogmo_entity) => {

	let promises = [];

	if (hasComponent(OgmoConsts.ComponentType.MESH, ogmo_entity)) {

		const meshComponent = getComponent(OgmoConsts.ComponentType.MESH, ogmo_entity);

		if (meshComponent.needsUpdate) {
			promises.push(createTriangleEntity(meshComponent.data, ogmo_entity));
			// Has to be set true if needs to update again
			setComponentNeedsUpdate(meshComponent, false);
		}
	}
	if (hasComponent(OgmoConsts.ComponentType.CAMERA, ogmo_entity)) {
		if (isViewer && !ogmo_camera_entity) {
			ogmo_camera_entity = ogmo_entity;
			const cameraComponent = getComponent(OgmoConsts.ComponentType.CAMERA, ogmo_camera_entity);
			if (cameraComponent.needsUpdate) {
				promises.push(updateCamera(ogmo_camera_entity));
				setComponentNeedsUpdate(cameraComponent, false);
			}
		}
	}

	if (hasComponent(OgmoConsts.ComponentType.LIGHT, ogmo_entity)) {
		const lightComponent = getComponent(OgmoConsts.ComponentType.LIGHT, ogmo_entity);

		if (lightComponent.needsUpdate) {
			promises.push(updateLight(lightComponent.data, ogmo_entity));
			if (!isViewer) {
				promises.push(createLightHelper(lightComponent, ogmo_entity));
			}
		}
	}

	if (hasComponent(OgmoConsts.ComponentType.SKYBOX, ogmo_entity)) {
		const skyComponent = getComponent(OgmoConsts.ComponentType.SKYBOX, ogmo_entity);
		if (skyComponent.needsUpdate) {
			promises.push(updateIBL(skyComponent.data, 0));
			setComponentNeedsUpdate(skyComponent, false);
		}
	}
	return promises;
};

const deleteEntities = (deletedEntityIds) => {

	deletedEntityIds.forEach(id => {
		let entity = entities[id];
		let helper = helpers[id];
		if (entity) {
			scene.remove(entity);
		}
		if (helper) {
			scene.remove(helper);
		}
	});
}

const updateIBL = async (sky, level) => {

	const sky_data = getSkyData(sky.type);

	if (!sky_data) {
		return 1;
	}
	return new Promise((resolve, reject) => {
		new RGBELoader()
        .setDataType( THREE.UnsignedByteType )
        .load( sky_data.hdr, ( texture ) => {

          const envMap = pmremGenerator.fromEquirectangular( texture ).texture;
          pmremGenerator.dispose();
          scene.environment = envMap;
		  resolve();

        }, undefined, reject );
	});
}

const updateLight = (light_data, ogmo_entity) => {

	var light = entities[ogmo_entity.id];

	if (light) {
		if (light_data.kind === OgmoConsts.LightType.DIRECTIONAL) {
			light.color.setRGB(light_data.color[0], light_data.color[1], light_data.color[2]);
			light.position.set(ogmo_entity.position[0], ogmo_entity.position[1], ogmo_entity.position[2]);
			light.intensity = light_data.intensity;
			light.castShadow = light_data.castShadows;
			// Update the shadow map
			renderer.shadowMap.needsUpdate = true;
		}
		else {
			console.log("Point Light Not Supported Yet in Three.");
			scene.remove(light);
		}
	} else {
		if (light_data.kind === OgmoConsts.LightType.DIRECTIONAL) {
			light = new THREE.DirectionalLight(new THREE.Color(), 1.0);
			light.color.setRGB(light_data.color[0], light_data.color[1], light_data.color[2]);
			light.position.set(ogmo_entity.position[0], ogmo_entity.position[1], ogmo_entity.position[2]);
			light.target.position.set(0, 0, 0);
			light.intensity = light_data.intensity;
			light.castShadow = light_data.castShadows;

			const d = 10;
			light.shadow.camera.left = light_data.shadowCameraDistance ? - light_data.shadowCameraDistance : - d;
			light.shadow.camera.right = light_data.shadowCameraDistance ? light_data.shadowCameraDistance : d;
			light.shadow.camera.top = light_data.shadowCameraDistance ? light_data.shadowCameraDistance : d;
			light.shadow.camera.bottom = light_data.shadowCameraDistance ? - light_data.shadowCameraDistance : - d;

			light.shadow.mapSize.width = light_data.shadowMapResolution ? light_data.shadowMapResolution : 1024;
			light.shadow.mapSize.height = light_data.shadowMapResolution ? light_data.shadowMapResolution : 1024;
		} else {
			console.log("Point Light Not Supported Yet in Three.");
		}
		scene.add(light);
		entities[ogmo_entity.id] = light;
		// Update the shadow map
		renderer.shadowMap.needsUpdate = true;
	}
	return 1;
}

const createTriangleEntity = async (mesh_comp, ogmo_entity) => {

	scene.remove(entities[ogmo_entity.id]);
	var entity = entities[ogmo_entity.id] = new THREE.Object3D();

	entity.name = ogmo_entity.id; // For ray cast hit filtering
	entity.matrixAutoUpdate = false;
	entities[ogmo_entity.id] = entity;

	if (mesh_comp.meshAssetId) {

		const mesh_asset = getAssetById(mesh_comp.meshAssetId);

		if (!mesh_asset) {
			return;
		}

		const model_data = mesh_asset.data;

		if (mesh_asset.type === OgmoConsts.AssetType.GLB) {

			const glbData = await loadGLB(model_data.glbPath);
			glbData.scene.traverse((child) => {
				if (child.isMesh) {
					child.name = ogmo_entity.id; // For ray cast hit filtering
					child.castShadow = mesh_comp.castShadows;
					child.receiveShadow = mesh_comp.receiveShadows;
				}
			});
			entity.add(glbData.scene.clone());
			
		  } else if (mesh_asset.type === OgmoConsts.AssetType.MESH) {

			const mesh = new THREE.Mesh();
			mesh.name = ogmo_entity.id; // For ray cast hit filtering
		    const vertices = new Float32Array(model_data.vertices);
			const indices = new Uint32Array(model_data.index);
			const normals = new Float32Array(model_data.normals);
			const texcoord0 = new Float32Array(model_data.texcoord0);

			const geometry = new THREE.BufferGeometry();

			const positionNumComponents = 3;
			const normalNumComponents = 3;
			const uvNumComponents = 2;

			geometry.setAttribute('position',new THREE.BufferAttribute(vertices, positionNumComponents));
			geometry.setAttribute('normal',new THREE.BufferAttribute(normals, normalNumComponents));
			geometry.setAttribute('uv',new THREE.BufferAttribute(texcoord0, uvNumComponents));
			geometry.setIndex(new THREE.BufferAttribute(indices, 1));
			mesh.geometry = geometry;
			mesh.material = OgmoCache.getMaterial(mesh_comp.materialAssetId);

			mesh.castShadow = mesh_comp.castShadows;
			mesh.receiveShadow = mesh_comp.receiveShadows;
			entity.add(mesh);

			renderer.shadowMap.needsUpdate = true;
		}

		scene.add(entity);

	}
	return 1;
}

const updateRenderer = (dt, entityIds) => {
	if (!initialized)
		return;
	updatePositions(entityIds);
	controls.update();
	composer.render();
};

const updatePositions = (entityIds) => {

	if (!entityIds) return;

	entityIds.forEach(id => {
		const three_entity = entities[id];
		const helper_entity = helpers[id];

		const transform = getTransform(id);

		if (three_entity && transform) {
			three_entity.matrix = new THREE.Matrix4();
			var m = new THREE.Matrix4().fromArray(transform.matrixWorld);
			three_entity.applyMatrix4(m);
		}

		if (helper_entity && transform && helper_entity.userData.type === OgmoConsts.LightType.DIRECTIONAL) {
			helper_entity.matrix = new THREE.Matrix4();
			let pos = mat4.getTranslation(vec3.create(), transform.matrixWorld);
			var m = new THREE.Matrix4().setPosition(new THREE.Vector3(pos[0], pos[1], pos[2]));
			helper_entity.applyMatrix4(m);

			let direction = vec3.create();
			vec3.sub(direction, vec3.create(), pos);
			vec3.normalize(direction, direction);

			helper_entity.children[0].setDirection(new THREE.Vector3(direction[0], direction[1], direction[2]));
		}
	});
}

const resize = () => {

	if (isViewer) {
		calculateWorldCenter();
	}

	var _fov = 45;
	var _near = 0.1;
	var _far = 1000.0;
	var _color = [0.2, 0.2, 0.2, 1];

	if (ogmo_camera_entity) {
		const camera_data = getComponent(OgmoConsts.ComponentType.CAMERA, ogmo_camera_entity).data;
		_fov = camera_data.fov;
		_near = camera_data.near;
		_far = camera_data.far;
		_color = camera_data.clearColor;
	}
	const width = canvas.width = canvas.clientWidth;
	const height = canvas.height = canvas.clientHeight;

	camera.fov = _fov;
	camera.near = _near;
	camera.far = _far;
	scene.background = new THREE.Color(_color[0], _color[1], _color[2]);

	camera.aspect = width / height;
	camera.updateProjectionMatrix();
	// set false here to prevent resize making the canvas big bug
	renderer.setSize(width, height, true);
	composer.setSize(width, height);
	effectFXAA.uniforms['resolution'].value.set(1 / width, 1 / height);
}

// Editor related code
const setupEditor = () => {
	var gridHelper = new THREE.GridHelper(12, 12);
	gridHelper.position.set(0.0, -0.001, 0.0);
	gridHelper.userData = { hideInARView : true};
	scene.add(gridHelper);

	// Set default position of the camera for editor
	camera.position.set(28, 21, 28);
	camera.fov = 54.43;
	camera.near = 0.1;
	camera.far = 2000.0;

	var axesHelper = new THREE.AxesHelper(2);
	axesHelper.userData = { hideInARView : true};
	scene.add(axesHelper);

	setupTransfromControls();

	// Editor reacts to dispaches
	store.subscribe(handleSceneChanges);
}

const setupTransfromControls = () => {
	transformControls = new TransformControls(camera, canvas);
	scene.add(transformControls);

	transformControls.traverse((obj) => { // To be detected correctly by OutlinePass.
		obj.isTransformControls = true;
	});

	transformControls.addEventListener('dragging-changed', (event) => {
		controls.enabled = !event.value;
	});

	transformControls.addEventListener('mouseDown', (event) => {
		eligibleForRaycast = false;
	});

	window.addEventListener('keydown', (event) => {
		switch (event.keyCode) {
			case 84: // T
				transformControls.setMode("translate");
				break;

			case 82: // R
				transformControls.setMode("rotate");
				break;

			case 83: // S
				transformControls.setMode("scale");
				break;
		}
	});
}

const handleSceneChanges = () => {
	let state = store.getState();

	if (state.scene.selectedEntitiyId != '' && state.scene.rootId != state.scene.selectedEntitiyId) {

		// remove existing helper
		scene.remove(objectHelper);
		transformControls.detach();
		transformControls.removeEventListener('mouseUp', handleTransformChange);
		outlinePass.selectedObjects = [];

		objectHelper = createHelper(state.scene.selectedEntitiyId);

		outlinePass.selectedObjects.push(objectHelper);
		objectHelper.userData = {hideInARView:true};
		scene.add(objectHelper);
		transformControls.attach(objectHelper);
		transformControls.addEventListener('mouseUp', handleTransformChange);
	} else {
		// remove existing helper
		scene.remove(objectHelper);
		transformControls.detach();
		outlinePass.selectedObjects = [];
		transformControls.removeEventListener('mouseUp', handleTransformChange);
	}
}

const createHelper = (entityId) => {
	let children = getAllChildrenRecursively(entityId)
	let helper = new THREE.Object3D();

	let transformHelper = getTransform(entityId);
	let matrixHelper = new THREE.Matrix4().fromArray(transformHelper.matrixWorld);
	matrixHelper.userData = {hideInARView:true};
	transformHelper.userData = {hideInARView:true};
	helper.applyMatrix4(matrixHelper);

	children.forEach(childId => {
		let childEntity = entities[childId];
		if (childEntity) {
			let transform = getTransform(childId);
			let matrix = new THREE.Matrix4().fromArray(transform.matrixWorld);
			let parentMatrix = new THREE.Matrix4().fromArray(transformHelper.matrixWorld);
			matrix = parentMatrix.invert().multiply(matrix);

			let child = childEntity.clone(true);
			child.matrix.identity();
			child.matrixWorld.identity();

			helper.add(child);

			child.applyMatrix4(matrix);
		}

	});

	return helper;
}

const createLightHelper = async (component, ogmo_entity) => {
	let helper = helpers[ogmo_entity.id];

	if (!helper) {
		let geometry = new THREE.SphereGeometry(0.2, 32, 32);
		var material = new THREE.MeshBasicMaterial();
		helper = new THREE.Mesh(geometry, material);;

		helper.userData = {
			type: OgmoConsts.LightType.DIRECTIONAL,
			hideInARView: true
		}

		helper.name = ogmo_entity.id;
		helper.matrixAutoUpdate = false;
		helpers[ogmo_entity.id] = helper;

		helper.castShadows = false;
		helper.receiveShadow = false;

		let arrow = new THREE.ArrowHelper(new THREE.Vector3(), new THREE.Vector3(), 1.0, 0xffff00, 0.25, 0.08);
		arrow.userData = {hideInARView : true};
		helper.add(arrow);

		scene.add(helper);
	}

	let color = new THREE.Color(component.data.color[0] * component.data.intensity,
		component.data.color[1] * component.data.intensity,
		component.data.color[2] * component.data.intensity);

	helper.material.color = color;
	helper.children[0].setColor(color);

	return 1;
}


const handleTransformChange = () => {
	let state = store.getState();
	let ogmo_entity = getEntityById(state.scene.selectedEntitiyId);

	let mode = transformControls.getMode();

	let eligitbleToRotate = true;
	let eligibleToScale = true;

	let helper = helpers[ogmo_entity.id];

	if (helper && helper.userData.type === OgmoConsts.LightType.DIRECTIONAL) {
		eligitbleToRotate = false;
		eligibleToScale = false;
	}

	if (ogmo_entity && ogmo_entity.parent) {

		let parent_transform = getTransform(ogmo_entity.parent);
		let matrixParent = new THREE.Matrix4().fromArray(parent_transform.matrixWorld);
		let matrixParentInverse = matrixParent.invert();

		if (mode === 'translate') {
			let pos = new THREE.Vector3();
			pos.copy(objectHelper.position);
			pos.applyMatrix4(matrixParentInverse);
			setPosition(state.scene.selectedEntitiyId, [pos.x, pos.y, pos.z]);

		}
		else if (mode === 'scale' && eligibleToScale) {
			let scale = new THREE.Vector3();
			scale.copy(objectHelper.scale);
			scale.divide(new THREE.Vector3().fromArray(parent_transform.scale));
			setScale(state.scene.selectedEntitiyId, [scale.x, scale.y, scale.z]);
		}
		else if (mode === 'rotate' && eligitbleToRotate) {
			let _quat = new THREE.Quaternion();
			_quat.copy(objectHelper.quaternion);
			_quat = _quat.normalize();

			let parentQuat = quat.create();
			quat.copy(parentQuat, parent_transform.quaternion);
			quat.invert(parentQuat, parentQuat);
			let quaternion = quat.fromValues(_quat.x, _quat.y, _quat.z, _quat.w);
			quat.multiply(quaternion, quaternion, parentQuat);

			let rotation = new THREE.Euler();
			rotation.setFromQuaternion(_quat, 'XYZ');

			setRotation(state.scene.selectedEntitiyId, [rotation.x * 180 / Math.PI, rotation.y * 180 / Math.PI, rotation.z * 180 / Math.PI]);
			setQuaternion(state.scene.selectedEntitiyId, [...quaternion]);
		}
	}
}

export { initializeRenderer, uninitializeRenderer, updateEntityRenderer, updateEntitiesRenderer, updateRenderer, createARAsset, cleanupLoadedObjectsRenderer }
