import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  snackPack: [],
  errText: null,
  isSnackBarShow: false
};

const snackBarSlice = createSlice({
  name: "snackBar",
  initialState,
  reducers: {
    pushToSnackPack(state, action) {
      state.snackPack.push({ message: action.payload, key: new Date().getTime() });
    },
    shiftSnackPack(state, action) {
      state.snackPack.shift();
    },
    setErrText(state, action) {
      state.errText = action.payload;
    },
    setIsSnackBarShow(state, action) {
      state.isSnackBarShow = action.payload;
    },
  }
});

export const {
  setSelectedIndex,
  pushToSnackPack,
  shiftSnackPack,
  setErrText,
  setIsSnackBarShow } = snackBarSlice.actions

export default snackBarSlice.reducer

