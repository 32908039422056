
import { sceneReducer } from './sceneReducer';
import { hotSpotReducer } from './hotSpotReducer'
import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from 'redux';

var rootReducer, store;

const createStore = (viewerSlice = null, snackBarReducer = null , dashboardReducer = null, ogmoDesignsReducer  = null, ecommerceProductsReducer = null, assetBrowserReducer = null, snackbarDashboardReducer=null, refImageReducer=null,organizationAndUserReducer=null) => {
	rootReducer = combineReducers({
		scene: sceneReducer,
		viewer: viewerSlice,
		dashboard: dashboardReducer,
		ogmoDesigns: ogmoDesignsReducer,
		ecommerceProducts: ecommerceProductsReducer,
		assetBrowser: assetBrowserReducer,
		hotSpot: hotSpotReducer,
		snackbar: snackBarReducer,
		snackbarDashboard: snackbarDashboardReducer,
		refImage: refImageReducer,
		organizationAndUser: organizationAndUserReducer
	})

	store = configureStore({
		reducer: rootReducer
	});

	return store;
}

export { store, createStore }