import { createSlice } from '@reduxjs/toolkit'

const initialState = {
	globalHotspotComponentId: "",
	globalHotspotGroupComponentId: ""
}
const hotSpotSlice = createSlice({
	name: "hotspot",
	initialState,
	reducers: {
		closeGlobalHotspotEditPanel(state, action) {
			state.globalHotspotComponentId= ""
		},
		closeGlobalHotspotGroupDetails(state, action) {
			state.globalHotspotGroupComponentId= ""
		},
		closeAllHotspotOptionPanels(state, action) {
			state.materialOptionId = ""
			state.optionId = ""
			state.globalHotspotComponentId= ""
			state.globalHotspotGroupComponentId= ""
		},
		setOpenGlobalHotspotDetails(state,action){
			state.globalHotspotComponentId = action.payload
		},
		setOpenGlobalHotspotGroupDetails(state,action){
			state.globalHotspotGroupComponentId = action.payload
		}
	}
});

export const {
	closeAllHotspotOptionPanels,
	setOpenGlobalHotspotDetails,
	closeGlobalHotspotEditPanel,
	setOpenGlobalHotspotGroupDetails,
	closeGlobalHotspotGroupDetails
} = hotSpotSlice.actions;

export const { reducer: hotSpotReducer } = hotSpotSlice;