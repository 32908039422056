
import { OgmoConsts } from '../constants/consts.js';
import { createUUID } from '../core/Utils.js';

import studio_icon from '../data/skybox/studio/icon/studio.jpg';
import studio_hdr from '../data/skybox/studio/equirectangular/studio.hdr';

import studio1_icon from '../data/skybox/studio1/icon/studio.jpg';
import studio1_hdr from '../data/skybox/studio1/equirectangular/studio.hdr';

import studio2_icon from '../data/skybox/studio2/icon/studio.jpg';
import studio2_hdr from '../data/skybox/studio2/equirectangular/studio.hdr';

import studio3_icon from '../data/skybox/studio3/icon/studio.jpg';
import studio3_hdr from '../data/skybox/studio3/equirectangular/studio.hdr';

import studio4_icon from '../data/skybox/studio4/icon/studio.jpg'
import studio4_hdr from '../data/skybox/studio4/equirectangular/studio.hdr';

import garage_icon from '../data/skybox/garage/icon/garage.jpg';
import garage_hdr from '../data/skybox/garage/equirectangular/garage.hdr';

import patio_icon from '../data/skybox/patio/icon/patio.jpg';
import patio_hdr from '../data/skybox/patio/equirectangular/patio.hdr';

import sunset_icon from '../data/skybox/sunset/icon/sunset.jpg';
import sunset_hdr from '../data/skybox/sunset/equirectangular/sunset.hdr';

import shipyard_icon from '../data/skybox/shipyard/icon/shipyard.jpg';
import shipyard_hdr from '../data/skybox/shipyard/equirectangular/shipyard.hdr';

import street1_icon from '../data/skybox/street1/icon/street1.jpg';
import street1_hdr from '../data/skybox/street1/equirectangular/street1.hdr';

import street2_icon from '../data/skybox/street2/icon/street2.jpg';
import street2_hdr from '../data/skybox/street2/equirectangular/street2.hdr';

import field_icon from '../data/skybox/field/icon/field.jpg';
import field_hdr from '../data/skybox/field/equirectangular/field.hdr';

export const SkyBoxComponent = () => ({
	type: OgmoConsts.ComponentType.SKYBOX,
	needsUpdate: true,
	id: createUUID(),
	name: "Sky Component",
	data: {
		type: OgmoConsts.SkyType.STUDIO,
		intensity: 30000,
		rotation: 0
	}
})

export const getSkyData = (type) => {
	var data = null;
	if (type === OgmoConsts.SkyType.STUDIO) {
		data = {
			icon: studio_icon,
			hdr : studio_hdr
		}
	} else if (type === OgmoConsts.SkyType.STUDIO1) {
		data = {
			icon: studio1_icon,
			hdr : studio1_hdr
		}
	} else if (type === OgmoConsts.SkyType.STUDIO2) {
		data = {
			icon: studio2_icon,
			hdr : studio2_hdr
		}
	} else if (type === OgmoConsts.SkyType.STUDIO3) {
		data = {
			icon: studio3_icon,
			hdr : studio3_hdr
		}
	}else if (type === OgmoConsts.SkyType.STUDIO4) {
		data = {
			icon: studio4_icon,
			hdr : studio4_hdr
		}
	} else if (type === OgmoConsts.SkyType.GARAGE) {
		data = {
			icon: garage_icon,
			hdr : garage_hdr
		}
	} else if (type === OgmoConsts.SkyType.PATIO) {
		data = {
			icon: patio_icon,
			hdr : patio_hdr
		}
	} else if (type === OgmoConsts.SkyType.SUNSET) {
		data = {
			icon: sunset_icon,
			hdr : sunset_hdr
		}
	} else if (type === OgmoConsts.SkyType.SHIPYARD) {
		data = {
			icon: shipyard_icon,
			hdr : shipyard_hdr
		}
	}else if (type === OgmoConsts.SkyType.STREET1) {
		data = {
			icon: street1_icon,
			hdr : street1_hdr
		}
	} else if (type === OgmoConsts.SkyType.STREET2) {
		data = {
			icon: street2_icon,
			hdr : street2_hdr
		}
	} else if (type === OgmoConsts.SkyType.FIELD) {
		data = {
			icon: field_icon,
			hdr : field_hdr
		}
	}

	return data;
}