import { createUUID } from '../core/Utils.js'

export function createComponent() {
	var Component = {
		type: "",
		id: createUUID(),
		needsUpdate: true,
		data: {}
	}
	return Component;
}