import React, { useState, useEffect } from 'react';
import './ARButton.css';
import arButtonIcon from '../../images/ARIcon.png'
import { getAssetById, OgmoConsts } from "ogmo_core";
import { useSelector, useDispatch } from "react-redux";
import { pushToSnackPack } from "../../reducers/snackBarSlice";
import QRCodePopUp from "../QRCode/QRCodePopUp"
import * as api from "../../services/api";

export default function ARButton() {

    const dispatch = useDispatch();
    const designId = useSelector(state => state.viewer.currentViewerDesignId);
    const [showQRCode, setShowQRCode] = useState(false);
    const [qrCode, setQrCode] = useState(undefined);
    const [isQRCodeLoading, setIsQRCodeLoading] = useState(false);

    //const [, , , getAssetById, ] = useAssets();
    const cmpts = useSelector(state => state.scene.componentByIds);
    const [isaimationPlaying, setIsAnimationPlaying] = useState(false);
    const isIPhoneDevice = /iPhone/g.test(navigator.userAgent);
    const isAndroidDevice = /android/i.test(navigator.userAgent);
    const isIPadDevice = /iPad/.test(navigator.userAgent) || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)

    let usdzComponentId = Object.keys(cmpts).find(componentId => {
        return cmpts[componentId].type === OgmoConsts.ComponentType.USDZ
    })

    let arComponentId = Object.keys(cmpts).find(componentId => {
        return cmpts[componentId].type === OgmoConsts.ComponentType.AR
    })

    let usdzComponent = cmpts[usdzComponentId];
    let arComponent = cmpts[arComponentId];

    let usdzAsset = usdzComponent && getAssetById(usdzComponent.data.usdzAssetId);
    let glbAsset = arComponent && getAssetById(arComponent.data.glbAssetId);
    let arUsdzAsset = arComponent && getAssetById(arComponent.data.usdzAssetId);

    let usdzPath = usdzAsset && usdzAsset.data.usdzPath;
    let glbPath = glbAsset && glbAsset.data.glbPath;

    // Override usdzPath by ARComponent data if both USDZComponent and ARComponent is available
    if (arComponent) {
        usdzPath = arUsdzAsset && arUsdzAsset.data.usdzPath;
    }

    const startGlowAnimation = () => {
        setIsAnimationPlaying(true);
        setTimeout(() => {
            setIsAnimationPlaying(false);
        }, 3000)

        window.analytics.track("AR Button", {
            action: "Clicked AR Button",
            label: "AR Button",
            category: "Click Event"
        });
    }

    let fallbackUrl = "";
    let androidIntentString = "intent://arvr.google.com/scene-viewer/1.0?file=" + glbPath + "&mode=ar_only#Intent;scheme=https;package=com.google.ar.core;action=android.intent.action.VIEW;S.browser_fallback_url=" + fallbackUrl + ";end;"

    useEffect(() => {
        if (glbPath && isAndroidDevice && !navigator.xr) {
            dispatch(pushToSnackPack("Sorry, your device or browser does not support AR"))
        }
    }, [glbPath && isAndroidDevice]);

    useEffect(() => {
        const controller = new AbortController();
        const generateViewerQRCode = async () => {
            try {
                setIsQRCodeLoading(true);
                let response = await api.get(`qrcode/${designId}`, controller.signal);
                setQrCode(response.qrCode);
                setIsQRCodeLoading(false);
            }
            catch (err) {
                if (err.name === 'AbortError') {
                    console.log('API call was aborted');
                } else {
                    setShowQRCode(false);
                    setIsQRCodeLoading(false);
                }
            }
        }
        if(designId) {
            generateViewerQRCode();
        }
        return () => {
            controller.abort();
            setQrCode(undefined);
        }

    }, [designId]);

    // TODO this is a duplicated code please refactor 
    const handleClickShowQRCode = async () => {
        setShowQRCode(true);
        if (!qrCode) {
            try {
                setIsQRCodeLoading(true);
                let response = await api.get(`qrcode/${designId}`);
                setQrCode(response.qrCode);
                setIsQRCodeLoading(false);
            }
            catch (err) {
                setShowQRCode(false);
                setIsQRCodeLoading(false);
                dispatch(pushToSnackPack("Something went wrong"));
            }
        }
    }

    const isEnableForAndroid = glbPath && isAndroidDevice && navigator.xr;
    const isEnableForIOS = usdzPath && (isIPadDevice || isIPhoneDevice);
    const IsDisplayGenerateQRCodeButton = !(isAndroidDevice || isIPhoneDevice || isIPadDevice) && (glbPath || usdzPath);

    return (
        <>
            {IsDisplayGenerateQRCodeButton &&
                <>
                    {showQRCode &&
                        <QRCodePopUp
                            setShowQRCode={setShowQRCode}
                            qrCode={qrCode}
                            isQRCodeLoading={isQRCodeLoading}
                        />
                    }
                    <div className="button-container">
                        <button className="qr-button" onClick={() => handleClickShowQRCode()}>
                            <img src={arButtonIcon} className="qr-icon" alt="" />
                            <label className="qr-label">Try it yourself!</label>
                        </button>
                        <div style={{ width:"60px", userSelect:"none" }} ></div>
                    </div>
                </>
            }
            {(isEnableForAndroid || isEnableForIOS) &&
                <div className="ar-button">
                    <a
                        className="ar-link"
                        href={isAndroidDevice ? androidIntentString : usdzPath}
                        rel="ar"
                        onTouchStart={startGlowAnimation}
                        style={{ animation: isaimationPlaying ? 'glowAnimation 2s 1' : 'none' }}
                    >
                        <img align="left" src={arButtonIcon} className="ar-icon" alt="" />
                        <label className="ar-label">Try it yourself!</label>
                    </a>
                </div>
            }
        </>
    )
}