import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    selectedOptionHotspotContainerIndex:null,
    selectedGlobalHotspotGroupId: "",
    selectedGlobalHotspotId: "",
    currentViewerDesignId:undefined
};

const viewerSlice = createSlice({
  name: "viewer",
  initialState,
  reducers: {
    setSelectedIndex(state,action){
      state.selectedOptionHotspotContainerIndex = action.payload;
    },
    unselectAllHotspotContainers(state,action){
      state.selectedOptionHotspotContainerIndex = null;
    },
    setSelectedGlobalHotspotGroupId(state, action){
      state.selectedGlobalHotspotGroupId = action.payload
    },
    setSelectedGlobalHotspotId(state, action){
      state.selectedGlobalHotspotId = action.payload
    },
    setCurrentViewerDesignId(state, action){
      state.currentViewerDesignId = action.payload
    },
  }
});

export const { 
  setSelectedIndex,
  unselectAllHotspotContainers,
  setSelectedGlobalHotspotGroupId, 
  setSelectedGlobalHotspotId,
  setCurrentViewerDesignId } = viewerSlice.actions

export default viewerSlice.reducer
