import { OgmoConsts } from '../constants/consts';
import { createUUID } from '../core/Utils';

export const MeshAsset = () => ({
	type: OgmoConsts.AssetType.MESH,
	name: "Mesh",
	id: createUUID(),
	data: {
		thumbnail: "",
		type: "triangles",
		primitiveType: "",
		count: 0,
		vertices: [],
		index: [],
		normals: [],
		texcoord0: [],
		texcoord1: [],
		min: [+ Infinity, + Infinity, + Infinity],
		max: [- Infinity, - Infinity, - Infinity],
		meshPath: ""
	}
});