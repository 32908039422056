import React from 'react';
import "./QRCodePopUp.css";
import ogmoLogo from "../../images/ogmo_logo.png";
import closeIcon from "../../images/close.png";

export default function QRCodePopUp({ setShowQRCode, qrCode, isQRCodeLoading }) {

    return (
        <>
            <div className="overlay" onClick={() => setShowQRCode(false)}>
                <div className="pop-up" onClick={(e) => e.stopPropagation()}>
                    <div className="qrcode-box">
                        <img className="ogmo-logo" src={ogmoLogo} alt="ogmo logo" />
                        {isQRCodeLoading ?
                            <div class="loader" >
                                <div class="bounce1"></div>
                                <div class="bounce2"></div>
                                <div class="bounce3"></div>
                            </div>
                            :
                            <img className="qr-image" src={qrCode} alt="" />
                        }
                        <p className="qr-text"> Works in ARKit supported iOS devices and ARCore supported Android devices</p>
                    </div>
                    <div className="discription-box">
                        <button className="close-button" onClick={() => setShowQRCode(false)}>
                            <img src={closeIcon} className="close-icon" alt="" />
                        </button>
                        <p className="discription-title">You are just one step away from the magic!</p>
                        <p className="discription-body">Scan the QR code from your smartphone or tablet for Augmented Reality experience.</p>
                    </div>
                </div>
            </div>
        </>
    )
}
