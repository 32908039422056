import { TextureAsset } from '../assets/TextureAsset';
import { MaterialAsset } from '../assets/MaterialAsset';
import { IconAsset } from '../assets/IconAsset';
import { addAsset as addAssetToScene, removeAsset as removeAssetFromScene, setSelectedAssetId } from './../reducer/sceneReducer'
import { store } from '../reducer/storeCreator'

import albedo1 from '../data/default/default_albedo.png';
import albedo0 from '../data/default/default_albedo0.png';
import normal1 from '../data/default/default_normal.png';
import normal0 from '../data/default/default_normal0.png';
import aorm1 from '../data/default/default_aorm.png';
import aorm0 from '../data/default/default_aorm0.png';
import { OgmoConsts } from '../constants/consts';
import { OgmoCache } from '../utils/Cache';

var defaultMatId = "";

const state = {
	meshAssetHeavyDataById: {}
}

export const HeavyMeshData = () => ({
	vertices: [],
	index: [],
	normals: [],
	texcoord0: [],
	texcoord1: [],
});

const createDefaultMaterial = () => {

	let material;
	if (!defaultMatId) {
		material = createMaterialAsset();
		const albedo = createTextureAsset();
		const normal = createTextureAsset();
		const aorm = createTextureAsset();

		albedo.data.originalTextures = [albedo0, albedo1];
		normal.data.originalTextures = [normal0, normal1];
		aorm.data.originalTextures = [aorm0, aorm1];

		material.data.albedoMap = albedo.id;
		material.data.normalMap = normal.id;
		material.data.aormMap = aorm.id;
		defaultMatId = material.id;
	} else {
		material = getAssetById(defaultMatId);
	}

	return material;
};

const createMaterialAsset = (kind) => {
	const material = MaterialAsset();
	material.data.kind = kind;
	store.dispatch(addAssetToScene(material));
	return material;
};

const createTextureAsset = (name = "", originalTextures = []) => {
	const texture = TextureAsset();
	texture.name = name;
	texture.data.originalTextures = originalTextures;
	store.dispatch(addAssetToScene(texture));
	return texture;
};

const createIconAsset = (name = "", iconImage = "") => {
	const icon = IconAsset();
	icon.name = name;
	icon.data.iconImage = iconImage;
	store.dispatch(addAssetToScene(icon));
	return icon;
};

const getAssetById = (id) => {
	let _state = store.getState();
	var asset = Object.assign({}, _state.scene.assetByIds[id]);
	// Fill back heavy data
	if (asset.type === OgmoConsts.AssetType.MESH) {
		const heavy_data = state.meshAssetHeavyDataById[asset.id];
		return {
			...asset,
			data: {
				...asset.data,
				vertices: heavy_data.vertices,
				index: heavy_data.index,
				normals: heavy_data.normals,
				texcoord0: heavy_data.texcoord0,
				texcoord1: heavy_data.texcoord1
			}
		}
	}
	return _state.scene.assetByIds[id];
};

const deleteAsset = (id) => {
	store.dispatch(removeAssetFromScene({ asset_id: id }));
};

const addAsset = (asset) => {

	if (asset.type === OgmoConsts.AssetType.MESH) {

		// Add heavy data to heavy data store
		const heavy_data = HeavyMeshData();
		heavy_data.vertices = [...asset.data.vertices];
		heavy_data.index = [...asset.data.index];
		heavy_data.normals = [...asset.data.normals];
		heavy_data.texcoord0 = [...asset.data.texcoord0];
		heavy_data.texcoord1 = [...asset.data.texcoord1];

		// Remove heavy data from Asset
		asset.data.vertices = [];
		asset.data.index = [];
		asset.data.normals = [];
		asset.data.texcoord0 = [];
		asset.data.texcoord1 = [];

		state.meshAssetHeavyDataById[asset.id] = { ...heavy_data };
	}
	store.dispatch(addAssetToScene(asset));
};

const setSelectedAsset = (id) => {
	store.dispatch(setSelectedAssetId({
		assetId : id
	}));
};

const createMaterialsFromMaterialAssets = async () => {
	let state = store.getState();

	for(var id in state.scene.assetByIds) {
		let asset = state.scene.assetByIds[id];
		if(asset.type === OgmoConsts.AssetType.MATERIAL) {
			await OgmoCache.addMaterialToCache(asset);
		}
	}
	return 1;
}

export { createDefaultMaterial, createMaterialAsset, createTextureAsset, getAssetById, addAsset, deleteAsset, createIconAsset, setSelectedAsset, createMaterialsFromMaterialAssets };
