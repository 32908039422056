import { store } from '../reducer/storeCreator'

const getComponent = (type, entity) => {
	var _component = null;
	let state = store.getState();

	if (entity) {
		entity.components.forEach(component => {
			if (component.type === type) {
				_component = state.scene.componentByIds[component.id];
			}
		});
	}

	return _component;
};

const getComponents = (type, entity) => {
	var _components = [];
	let state = store.getState();

	if (entity) {
		entity.components.forEach(component => {
			if (component.type === type) {
				_components.push(state.scene.componentByIds[component.id]);
			}
		});
	}
	return _components;
};

const getComponentById = (id) => {
	let state = store.getState();
	return state.scene.componentByIds[id];
};

const hasComponent = (type, entity) => {
	var _hasComponent = false;
	let state = store.getState();

	if (entity) {
		entity.components.forEach(component => {
			if (component.type === type && state.scene.componentByIds[component.id]) {
				_hasComponent = true;
			}
		});
	}

	return _hasComponent;
};

export { hasComponent, getComponent, getComponentById, getComponents }
