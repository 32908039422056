import React, { useEffect } from 'react';
import './SnackBarMessage.css';
import UseSnackBarMessage from "../UseSnackBarMessage";

const SnackBarMessage = () => {

  const [errText, isSnackBarShow] = UseSnackBarMessage();

  const styleClassName = isSnackBarShow ? "show" : "";

  return (
    <div id="snackbar" className={styleClassName} key={errText ? errText.key : undefined} >
      {errText ? errText.message : undefined}
    </div>
  );
}

export default SnackBarMessage;
