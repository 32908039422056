import {
	initializeRenderer,
	uninitializeRenderer,
	updateEntityRenderer,
	updateEntitiesRenderer,
	updateRenderer,
	createARAsset,
	cleanupLoadedObjectsRenderer
} from '../systems/ThreeRenderSystem.js';
import { emptyDeletedEntities, resetScene as resetSceneReducer } from './../reducer/sceneReducer';
import { addEntities as addEntitiesToTransformManager } from '../ecs/useTransformManager.js';
import { store } from '../reducer/storeCreator'
import { OgmoCache } from '../utils/Cache.js';
import { createMaterialsFromMaterialAssets } from './useAssets.js';

var canvas = null;
var initialized = false;
var state;

const uninitializeScene = () => {
	initialized = false;
	uninitializeRenderer();
}

const initializeScene = (_canvas, _canvasDebug, _onInitialized, isViewer = true) => {

	canvas = _canvas;

	initializeRenderer(canvas, () => {
		console.log("Renderer Initialized");
		initialized = true;
		updateScene();
		store.subscribe(updateEntities);
		_onInitialized();
	}, isViewer);
};

const updateEntity = (entity) => {

	if (!initialized) {
		return;
	}
	updateEntityRenderer(entity);

};

const updateEntities = async () => {

	if (!initialized) {
		return;
	}
	createMaterialsFromMaterialAssets();
	await addEntitiesToTransformManager();
	await updateEntitiesRenderer();
	// Cleanup the deleted entities array
	//store.dispatch(emptyDeletedEntities()); // This should be called when save happens
};

const updateScene = () => {

	if (!initialized) {
		return;
	}
	state = store.getState();

	updateRenderer(0, state.scene.entityIds);
	requestAnimationFrame(updateScene);
}

const resetScene = () => {
	store.dispatch(resetSceneReducer());
	cleanupLoadedObjectsRenderer();
}

const createARAssetFromScene = ( uploadGLBFile) => {
	createARAsset(uploadGLBFile);
}

export { initializeScene, uninitializeScene, updateEntity, updateEntities, resetScene, createARAssetFromScene }