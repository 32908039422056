import { addEntity } from './../reducer/sceneReducer';
import { addChildToEntity, removeEntity, setSelectedEntityId, setEntityName as setEntityNameStore, removeComponent } from './../reducer/sceneReducer';
import { store } from '../reducer/storeCreator'
import {  getAllChildrenIds } from "../utils/helper.js";

const getEntityByName = (name) => {
	var _entity = null;
	let state = store.getState();
	for (var key in state.scene.entityByIds) {
		var e = state.scene.entityByIds[key];
		if (e.name === name) {
			_entity = e;
			break;
		}
	}
	return _entity;
};

const getEntityById = (id) => {
	let state = store.getState();
	var _entity = state.scene.entityByIds[id];
	return _entity;
};

// todo this method body is not correct
const setChild = (child, entity) => {
	let state = store.getState();
	var parent;
	if (entity) {
		parent = getEntityById(entity.id);
	} else {
		parent = getEntityById(state.scene.rootId);
	}
	child.parent = parent.id; // todo, do we need this anymore?

	store.dispatch(addEntity({
		entityId: child.id,
		entity: child
	}))
	store.dispatch(addChildToEntity({
		parentId: parent.id,
		childId: child.id
	}))
};

const deleteEntity = (id) => {
	let state = store.getState();
	if (id === state.scene.rootId) {
		alert("Cannot Delete The Root");
	} else {
		let entities = state.scene.entityByIds
		let childrenEntityIds = getAllChildrenIds(entities, id);
		const entityIdsToBeDeleted = [...childrenEntityIds, id]

		entityIdsToBeDeleted.forEach(entityId => {
			entities[entityId].components.forEach(component => {
				store.dispatch(removeComponent({ id: component.id }));
			});
		})
		store.dispatch(removeEntity({ entityId: id }))
	}
};

const setSelectedEntity = (id) => {
	store.dispatch(setSelectedEntityId({
		entityId: id
	}));
};

const getAllChildrenRecursively = (id) => {
	const child = getEntityById(id);
	let children = [];
	child.children.forEach(childId => {
		children = [...children, ...getAllChildrenRecursively(childId)];
	});
	return [child.id, ...children];
}

const setEntityName = (id, name) => {
	store.dispatch(setEntityNameStore({
		entityId: id,
		name: name
	}))
}

export { getEntityByName, getEntityById, setChild, deleteEntity, setSelectedEntity, getAllChildrenRecursively, setEntityName }

