import React from 'react';
import './DragToMoveHint.css';
import handIcon from "../../images/hand.png";

export default function DragToMoveHint({didUserClickTheCanvas}){
    return(
        <div id="hand" style={{ display: didUserClickTheCanvas ? 'none' : 'block' }}>
            <img id="hand-move-animation-icon" src={handIcon} alt="" />
        </div>
    )
}