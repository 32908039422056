const OgmoConsts = {
	ComponentType: {
		MESH: "meshComponent",
		LIGHT: "lightComponent",
		CAMERA: "cameraComponent",
		PHYSICS: "physicsComponent",
		SKYBOX: "skyboxComponent",
		LOCAL_MAT_CONFIG: "matlocalComponent",
		MATERIAL_OPTION: "materialOptionComponent",
		MESH_OPTION: "meshOptionComponent",
		USDZ: "usdzComponent",
		AR:"arComponent",
		GLOBAL_HOTSPOT: "globalHotspotComponent",
		GLOBAL_HOTSPOT_GROUP: "globalHotspotGroupComponent"
	},
	AssetType: {
		MESH: "meshAsset",
		MATERIAL: "materialAsset",
		TEXTURE: "textureAsset",
		MODEL: "modelAsset",
		USDZ: "usdzAsset",
		ICON: "iconAsset",
		GLB:"glbAsset",
	},
	MaterialType: {
		LIT: "Lit",
		UNLIT: " Unlit",
		CLOTH: "Cloth",
		INVISIBLE_SHADOW: "Shadow"
	},
	MeshPrimitiveType: {
		PLANE: "Plane",
		BOX: "Box"
	},
	LightType: {
		DIRECTIONAL: "directional",
		POINT: "point"
	},
	SkyType: {
		STUDIO: "studio",
		STUDIO1: "studio1",
		STUDIO2: "studio2",
		STUDIO3: "studio3",
		STUDIO4: "studio4",
		GARAGE: "garage",
		PATIO: "patio",
		SUNSET: "sunset",
		SHIPYARD: "shipyard",
		STREET1: "street1",
		STREET2: "street2",
		FIELD: "field"
	},
	Resolutions: {
		RESOLUTION1: 512,
		RESOLUTION2: 1024,
		RESOLUTION3: 2048
	}
}

export { OgmoConsts }