import { MeshAsset } from "../assets/MeshAsset";
import { calculateBoundingBox } from "../core/Utils";
import { OgmoConsts } from "../constants/consts";

export function generatePlaneMeshAsset(width, height, widthSegments, heightSegments) {
	width = width || 1;
	height = height || 1;

	var width_half = width / 2;
	var height_half = height / 2;

	var gridX = Math.floor(widthSegments) || 1;
	var gridY = Math.floor(heightSegments) || 1;

	var gridX1 = gridX + 1;
	var gridY1 = gridY + 1;

	var segment_width = width / gridX;
	var segment_height = height / gridY;

	var ix, iy;

	// buffers

	var indices = [];
	var vertices = [];
	var normals = [];
	var uvs = [];

	// generate vertices, normals and uvs

	for (iy = 0; iy < gridY1; iy++) {
		var y = iy * segment_height - height_half;

		for (ix = 0; ix < gridX1; ix++) {
			var x = ix * segment_width - width_half;
			vertices.push(x, - y, 0);
			normals.push(0, 0, 1);
			uvs.push(ix / gridX);
			uvs.push(1 - (iy / gridY));
		}
	}

	// indices
	for (iy = 0; iy < gridY; iy++) {
		for (ix = 0; ix < gridX; ix++) {
			var a = ix + gridX1 * iy;
			var b = ix + gridX1 * (iy + 1);
			var c = (ix + 1) + gridX1 * (iy + 1);
			var d = (ix + 1) + gridX1 * iy;

			// faces
			indices.push(a, b, d);
			indices.push(b, c, d);
		}
	}

	const plane = MeshAsset();
	const bbox = calculateBoundingBox(vertices);
	plane.data.min = bbox.min;
	plane.data.max = bbox.max;

	plane.data.primitiveType = OgmoConsts.MeshPrimitiveType.PLANE;

	plane.data.count = indices.length;
	plane.data.vertices = vertices;
	plane.data.index = indices;
	plane.data.normals = normals;
	plane.data.texcoord0 = uvs;

	return plane;
}

export function generateGrid(size, divisions) {

	var vertices = [];
	var indices = [];

	var size = size || 10;
	var divisions = divisions || 10;

	var step = size / divisions;
	var halfSize = size / 2;

	for (var i = 0, k = - halfSize; i <= divisions; i++, k += step) {
		vertices.push(- halfSize, 0, k, halfSize, 0, k);
		vertices.push(k, 0, - halfSize, k, 0, halfSize);
	}

	indices = [...Array(vertices.length / 3).keys()]

	return {
		vertices: vertices,
		index: indices,
		color: [0.239, 0.282, 0.247]
	}
}