import React, { useEffect } from "react";
import "./MobileHotspotPanelContent.css"
import { useDispatch, useSelector } from "react-redux";
import { setSelectedGlobalHotspotGroupId } from "../../reducers/viewerSlice";
import GlobalHotspot from '../GlobalHotspot/GlobalHotspot'


export default function MobileHotspotPanelContent() {

    const dispatch = useDispatch();
    const cmpts = useSelector(state => state.scene.componentByIds);
    const selectedGlobalHotspotGroupId = useSelector(state => state.viewer.selectedGlobalHotspotGroupId)
    const allGlobalHotspotGroupComponents = Object.keys(cmpts).filter(key => cmpts[key].type === "globalHotspotGroupComponent")

    useEffect(() => {
        dispatch(setSelectedGlobalHotspotGroupId(allGlobalHotspotGroupComponents[0]))
    }, [])

    const selectedAHotspotGroup = (globalHotspotGroupComponent) => {
        dispatch(setSelectedGlobalHotspotGroupId(globalHotspotGroupComponent))
    }

    return (
        <div className="globalHotspotGroupMobile">
            <div className="customize-your-product">Customize your product</div>
            <div className="groupTabMobile">
                {allGlobalHotspotGroupComponents.map((globalHotspotGroupComponent) => (
                    <button
                        className={selectedGlobalHotspotGroupId === globalHotspotGroupComponent ? "groupButtonActive" : "groupButton"}
                        onClick={() => selectedAHotspotGroup(globalHotspotGroupComponent)}
                    >
                        {cmpts[globalHotspotGroupComponent].data.displayName}
                    </button>
                ))}
            </div>
            <div className="groupContent">
                {allGlobalHotspotGroupComponents.map((globalHotspotGroupComponent) => (
                    <div className={selectedGlobalHotspotGroupId === globalHotspotGroupComponent ? "hotspotContainerMobile" : "hidden"}>
                        {cmpts[globalHotspotGroupComponent].data.selectedGlobalHotspots.map((globalHotspotId) => {
                          if (cmpts[globalHotspotId]){
                            let globalHotspotData = cmpts[globalHotspotId].data
                            return (
                                <GlobalHotspot
                                    globalHotspotId={globalHotspotId}
                                    globalHotspotData={globalHotspotData}
                                />
                            )
                          }
                        })
                        }
                    </div>
                ))}
            </div>
        </div>
    )
}