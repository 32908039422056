import React, { useEffect, useState, useMemo } from "react";
import "./App.css";
import { initializeScene, uninitializeScene, loadScene , resetScene} from "ogmo_core";
import * as api from "../src/services/api";
import DragToMoveHint from "./components/DragToMoveHint/DragToMoveHint";
import PreLoader from "./components/PreLoader/PreLoader";
import SnackBarMessage from "./components/SnackBarMessage/SnackBarMessage";
import { useDispatch, useSelector } from "react-redux";
import GroupDesignOverlay from "./components/GroupDesignOverlay/GroupDesignOverlay";
// import FPSStats from "react-fps-stats";
import { pushToSnackPack } from '../src/reducers/snackBarSlice';
import GitInformation from './GitInformation.json';
import { version } from './version.json';
import useWindowDimensions from "./components/useWindowDimensions";
import GlobalHotspotSection from "./components/GlobalHotspotSection/GlobalHotspotSection"
import { setCurrentViewerDesignId, setSelectedGlobalHotspotId } from "./reducers/viewerSlice";

function App() {
  const ref = React.useRef();
  //const loadScene = useSceneLoader();
  const dispatch = useDispatch();

  const currentViewerDesignId = useSelector(state => state.viewer.currentViewerDesignId);
  const [isSceneLoaded, setIsSceneLoaded] = useState(false);
  const [designsList, setDesignsList] = useState([]);
  const [didUserClickTheCanvas, setDidUserClickTheCanvas] = useState(false);
  const [groupName, setGroupName] = useState();
  const [isOpenedHotspotPanel, setIsOpenedHotspotPanel] = useState(false)
  const { height } = useWindowDimensions();


  useEffect(() => {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  }, [height])

  useEffect(() => {
    api
      .get(`getversion`)
      .then(data => {
        const BRANCH = GitInformation.branch;
        const SHA = GitInformation.short_sha;
        const STAGE = GitInformation.stage;

        var git_ver = GitInformation.version.split('-');
        var num_commits = `-${git_ver[1]}`;
        var short_sha = `-${SHA}`
        var git_branch = "";

        if(STAGE === "prod"){
            git_branch = "";
            if(git_ver.length === 1){
              num_commits = "";
            }
        }
        else if(STAGE === "dev"){
          git_branch = "-alpha";
        }
        else if(STAGE === "uat"){
          git_branch = "-beta";
        }
        else if(STAGE === "topic"){
          git_branch = `-${BRANCH}`;
        }

        GitInformation.version = `v${version}${num_commits}${git_branch}${short_sha}`
        console.log("ogmo backend : ", data)
        console.log("3d viewer : ", GitInformation)
      })
      .catch(error => {
        dispatch(pushToSnackPack(error.message));
        console.log(error);
      });
  }, []);

  const selectedOptionHotspotContainerIndex = useSelector(state => state.viewer.selectedOptionHotspotContainerIndex)

  useEffect(() => {
    const url = window.location.href;
    api
      .get(`viewer?design_id=${url.split("=")[1]}`)
      .then(res => {
        let sortedList =  res.designs.sort((a, b) => a.position - b.position);
        setGroupName(res.name);
        setDesignsList(sortedList);
        dispatch(setCurrentViewerDesignId(res.designs[0].id));
      })
      .catch(err => {
        dispatch(pushToSnackPack(err.message));
        console.log(err);
      });
  }, []);

  useEffect(() => {
    if (currentViewerDesignId) {
      let key = Object.keys(designsList).find((key) => designsList[key].id === currentViewerDesignId);
      handleLoad(designsList[key].scene);
    }
  }, [currentViewerDesignId]);

  useEffect(() => {
    const canvas = ref.current;
    initializeScene(canvas, null, () => {
      console.log("Scene Initialized");
    });
    return () => {
      uninitializeScene();
    }
  }, []);

  useEffect(() => {
    window.analytics.load("unZi64qsPABD4JdRhGHC7z2tAgaHAJzY");
    window.analytics.page();
  }, []);
  
  const handleLoad = url => {
    resetScene();
    setIsSceneLoaded(false);
    loadScene(url, (defaultGlobalHotspotId) => {
      dispatch(setSelectedGlobalHotspotId(defaultGlobalHotspotId))
      setIsSceneLoaded(true);
    }, () => {
      dispatch(pushToSnackPack("Something went wrong while loading the scene"));
    });
  };

  const trackEvent = () => {
    window.analytics.track("Canvas", {
      action: "Clicked on Canvas",
      label: "Canvas",
      category: "Click Event"
    });
  }

  const isAGroup = designsList.length>1 ;

  return (
    <div>
      <SnackBarMessage />
      {/* <FPSStats/> */}
      <div className="body">
        {(isAGroup && !isOpenedHotspotPanel) && <GroupDesignOverlay
          designsList={designsList}
          groupName={groupName}
          isSceneLoaded={isSceneLoaded}
        />}
        <canvas
          ref={ref}
          className="canvas"
          onClick={trackEvent}
          onMouseDown={() => isSceneLoaded && setDidUserClickTheCanvas(true)}
          onTouchStart={() => isSceneLoaded && setDidUserClickTheCanvas(true)}
          onTouchEnd={trackEvent}
        />
        <GlobalHotspotSection
          isOpenedHotspotPanel={isOpenedHotspotPanel}
          setIsOpenedHotspotPanel={setIsOpenedHotspotPanel}
        />
        {isSceneLoaded &&
          <DragToMoveHint
            didUserClickTheCanvas={didUserClickTheCanvas}
          />
        }
        {!isSceneLoaded &&
          <PreLoader />
        }
      </div>
    </div>
  );
}

export default App;
