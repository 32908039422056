export const getAllChildrenIds = (entityByIds, entityId) => {

    let entity = entityByIds[entityId];
    let childrenIds = [];

    if (entity) {
        for (var key in entity.children) {
            let child = entityByIds[entity.children[key]];
            childrenIds.push(child.id);
            childrenIds = childrenIds.concat(getAllChildrenIds(child.id));
        }
    }
    return childrenIds;
}