import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import shopifyAddToCartIcon from "../../images/shopifyAddToCart.png";
import "./styles.css";

const AddToCartButton = ({platform, siteURL}) => {
  const cmpts = useSelector((state) => state.scene.componentByIds);
  const selectedGlobalHotspotId = useSelector((state) => state.viewer.selectedGlobalHotspotId);
  
  const isIPhoneDevice = /iPhone/g.test(navigator.userAgent);
  const isAndroidDevice = /android/i.test(navigator.userAgent);
  const isIPadDevice = /iPad/.test(navigator.userAgent) || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)
  const isMobileDevice = isIPadDevice || isAndroidDevice || isIPhoneDevice

  const selectedProductVariantId = useMemo(() => {
    if (selectedGlobalHotspotId && siteURL) {
      let variantId;
      if (platform === "shopify") {
        variantId = getShopifyVariantForSelectedHotspot()
      }
      if(variantId) return variantId; 
    }
  },[selectedGlobalHotspotId]);

  function getShopifyVariantForSelectedHotspot () {
    const shopifyVariantData = cmpts[selectedGlobalHotspotId].data.assignedProductVariants.find(
      variant => variant.platform === "shopify"
    )
    return shopifyVariantData.variantId
  }

  window.addEventListener("message",(event) => {
      console.log(event.data.variants);
  },false);

  function handleAddToCart(e) {
    e.preventDefault()
    if (platform === "shopify") {
      addToCartOfShopifyStore()
    }
  }

  function addToCartOfShopifyStore() {
      const payload = {
        variantId: selectedProductVariantId, // comes from the selected global hotspot
        quantity: 1,
      };
      window.parent.postMessage(JSON.stringify(payload), siteURL);
  }

  return selectedProductVariantId ? (
      <div className={`add-to-cart-button ${isMobileDevice ? "add-to-cart-button-mobile" : "add-to-cart-button-desktop"}`} onClick={handleAddToCart}>
        <img
          align="left"
          src={shopifyAddToCartIcon}
          className={isMobileDevice ? "add-to-cart-icon" : "add-to-cart-icon-desktop"}
          alt="Shopify Add to Cart Icon"
        />
        <label className={isMobileDevice ? "add-to-cart-label" : "add-to-cart-label-desktop" }>Add to Cart</label>
      </div>
  ) : null
};

export default AddToCartButton;
