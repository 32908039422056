import * as THREE from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js'

const glbCache = {};

const loadGLB = (path) => {

    const glbScene = glbCache[path]

    if (glbScene) {
        return glbScene;
    }

    const loader = new GLTFLoader();

    return new Promise((resolve, reject) => {
        loader.load(path, data => {
            data.scene.traverse(obj => {
                if(obj.isMesh) {
                    if (obj.material.map) obj.material.map.encoding = THREE.LinearEncoding;
                    if (obj.material.emissiveMap) obj.material.emissiveMap.encoding = THREE.LinearEncoding;
                    if (obj.material.metalnessMap) obj.material.metalnessMap.encoding = THREE.LinearEncoding;
                    if (obj.material.roughnessMap ) obj.material.roughnessMap.encoding = THREE.LinearEncoding;  
                    obj.material.needsUpdate = true;
                }
            })
            glbCache[path] = data;
            resolve(data)
        }, null, reject);
    });
}

export { loadGLB }