import { addComponent } from "./../reducer/sceneReducer"
import { addComponentToEntity } from "./../reducer/sceneReducer"
import { removeComponent } from "./../reducer/sceneReducer"
import { setComponentNeedsUpdate as setComponentNeedsUpdateDispatch, updateMeshComponentData } from "./../reducer/sceneReducer"
import { store } from '../reducer/storeCreator'

const setComponentNeedsUpdate = (component, state) => {
	if (!component) {
		return;
	}
	store.dispatch(setComponentNeedsUpdateDispatch({
		componentId: component.id,
		state: state
	}));

}

const setComponent = (component, entity) => {

	if (!entity) {
		return;
	}
	store.dispatch(addComponent(component))
	store.dispatch(addComponentToEntity({
		componentForTheEntity: { id: component.id, type: component.type },
		entityId: entity.id
	}))
};

const setSelectedGlobalHotspot = (selectedGlobalHotspotId) => {
	let state = store.getState();
	const cmpts = state.scene.componentByIds;
	const globalHotspotData = cmpts[selectedGlobalHotspotId].data
	const targetMeshMaterialOptions =  globalHotspotData.targetMeshMaterialOptions

	Object.keys(targetMeshMaterialOptions).map(optionId => {

		const isAValidOption = () => {
			return targetMeshMaterialOptions[optionId].targetMeshComponentId
		}
		
		if(isAValidOption()){ //To check whether this option is abondened by the editor. ie editor has forgotten to assign a mesh component to an option
			store.dispatch(updateMeshComponentData({
				componentId: targetMeshMaterialOptions[optionId].targetMeshComponentId,
				updateField: "meshAssetId",
				value: targetMeshMaterialOptions[optionId].targetMeshAssetId
			}));
			store.dispatch(updateMeshComponentData({
				componentId: targetMeshMaterialOptions[optionId].targetMeshComponentId,
				updateField: "materialAssetId",
				value: targetMeshMaterialOptions[optionId].targetMaterialAssetId
			}));
		}
	})
}

const deleteComponent = (component) => {

	if (!component) {
		return;
	}
	store.dispatch(removeComponent({ id: component.id }));
};

export { setComponent, deleteComponent, setComponentNeedsUpdate, setSelectedGlobalHotspot }