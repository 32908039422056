import React from 'react';
import './ErrorBoundary.css';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: null, errorInfo: null };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({
      error: error,
      errorInfo: errorInfo
    })
    console.log(errorInfo.componentStack)
    console.log(error)
  }
  
  render() {
    if (this.state.errorInfo) {
      return (
        <div id="errorBoundary">Something went wrong.</div>
      );
    }
    return this.props.children;
  }
}
export default ErrorBoundary;