import { getEntity } from '../ecs/Entity.js'
import { OgmoConsts } from '../constants/consts.js';
import { createSlice } from '@reduxjs/toolkit'
import { getAllChildrenIds } from "../utils/helper.js";

const findAndUpdateComponetWithUpdatedMaterial = (componentByIds, assetID) => {
	const oldComponetsByIds = componentByIds;
	for (var key in componentByIds) {
		var component = componentByIds[key];
		if (component.type === OgmoConsts.ComponentType.MESH) {
			if (component.data.materialAssetId === assetID) {
				component.needsUpdate = true;
				oldComponetsByIds[component.id] = component;
			}
		}
	}
	return oldComponetsByIds;
}

const en = getEntity();
const initialState = {
	selectedEntitiyId: en.id,
	selectedAssetId: "",
	rootId: en.id,
	entityIds: [en.id],
	entityByIds: { [en.id]: en },
	componentIds: [],
	componentByIds: {},
	assetIds: [],
	assetByIds: {},
	deletedAssets: [],
	hotSpotMeshAsset: "",
	deletedEntityIds: []
}

const sceneSlice = createSlice({
	name: "scene",
	initialState,
	reducers: {
		resetScene(state, action) {
			const en = getEntity();
			state.selectedEntitiyId = en.id;
			state.selectedAssetId = "";
			state.rootId = en.id;
			state.entityIds = [en.id];
			state.entityByIds = { [en.id]: en };
			state.componentIds = [];
			state.componentByIds = {};
			state.assetIds = [];
			state.assetByIds = {};
			state.deletedAssets = [];
			state.deletedEntityIds = [];
		},
		addAsset(state, action) {
			state.assetIds.push(action.payload.id);
			state.assetByIds[action.payload.id] = action.payload;
		},
		setSelectedEntityId(state, action) {
			/*
				entityId : Selected entity id
			*/
			state.selectedEntitiyId = action.payload.entityId;
			state.selectedAssetId = "";
		},
		setSelectedAssetId(state, action) {
			/*
				assetId : Selected asset id
			*/
			state.selectedAssetId = action.payload.assetId;
			state.selectedEntitiyId = "";
		},
		addComponent(state, action) {
			state.componentIds.push(action.payload.id);
			state.componentByIds[action.payload.id] = action.payload;
		},
		addComponentToEntity(state, action) {
			const entityIdToModify = action.payload.entityId;
			state.entityByIds[entityIdToModify].components.push(action.payload.componentForTheEntity);
		},
		removeComponent(state, action) {

			const idOfTheComponentToBeDeleted = action.payload.id;

			if (state.componentByIds[idOfTheComponentToBeDeleted].type === OgmoConsts.ComponentType.GLOBAL_HOTSPOT) {
				const hotspotGroupIds = Object.keys(state.componentByIds).filter(componentId => state.componentByIds[componentId].type === OgmoConsts.ComponentType.GLOBAL_HOTSPOT_GROUP)
 
				hotspotGroupIds.forEach(group_id => {
					const globalHotspotIdsInGroup = state.componentByIds[group_id].data.selectedGlobalHotspots; //these are the global hotspots assigned to the group
					if (globalHotspotIdsInGroup) {
						globalHotspotIdsInGroup.forEach(globalHotspotId => { 
							if (globalHotspotId === idOfTheComponentToBeDeleted) { //should break the parent forEach. One hotspot cannot be in two groups.
								const filteredGlobalHotspotIds = globalHotspotIdsInGroup.filter(id => id != idOfTheComponentToBeDeleted) 
								state.componentByIds[group_id].data.selectedGlobalHotspots = filteredGlobalHotspotIds;
								state.componentByIds[group_id].needsUpdate = true;
							}
						})
					}
				})
			} 

			if (state.componentByIds[idOfTheComponentToBeDeleted].type === OgmoConsts.ComponentType.MESH) {

				const hotspotIds = Object.keys(state.componentByIds).filter(componentId => state.componentByIds[componentId].type === OgmoConsts.ComponentType.GLOBAL_HOTSPOT)

				hotspotIds.forEach(hotspot_id => {
					const optionsInHotspot = state.componentByIds[hotspot_id].data.targetMeshMaterialOptions;

					Object.keys(optionsInHotspot).forEach((key) => { //iterating over each option in the particular hotspot
						if (optionsInHotspot[key].targetMeshComponentId === idOfTheComponentToBeDeleted) {
							state.componentByIds[hotspot_id].needsUpdate = true;
							optionsInHotspot[key].targetMeshComponentId = "";
							optionsInHotspot[key].targetMeshAssetId = "";
							optionsInHotspot[key].targetMaterialAssetId = "";
						}
					})
				})
			}

			delete state.componentByIds[idOfTheComponentToBeDeleted];
			state.entityByIds[state.selectedEntitiyId].components = state.entityByIds[state.selectedEntitiyId].components.filter(entities => entities.id != idOfTheComponentToBeDeleted);
			state.componentIds = state.componentIds.filter(id => id != idOfTheComponentToBeDeleted);
		},
		updateComponentName(state, action) {
			/*  
				componentId: This is the component id
				value : This is the value to be set
			*/
  
			let oldmeshComponent = state.componentByIds[action.payload.componentId];
			oldmeshComponent.data[action.payload.updateField] = action.payload.value;
			oldmeshComponent.needsUpdate = true;
			state.componentByIds[action.payload.componentId]=oldmeshComponent;
		},
		/* Functionalities related to Entity */
		addEntity(state, action) {
			state.entityIds.push(action.payload.entityId);
			state.entityByIds[action.payload.entityId] = action.payload.entity;
		},
		addChildToEntity(state, action) {
			state.entityByIds[action.payload.parentId].children.push(action.payload.childId);
		},
		setEntityPosition(state, action) {
			/*  
				entityId: This is the entity id
				position: [x, y, z] position to be set
			*/
			let oldEntitiy = state.entityByIds[action.payload.entityId];
			oldEntitiy.position = action.payload.position;
			state.entityByIds[action.payload.id] = oldEntitiy;
		},
		setEntityScale(state, action) {
			/*  
				entityId: This is the entity id
				scale: [x, y, z] position to be set
			*/
			let oldEntitiy = state.entityByIds[action.payload.entityId];
			oldEntitiy.scale = action.payload.scale;
			state.entityByIds[action.payload.id] = oldEntitiy;
		},
		setEntityRotation(state, action) {
			/*  
				entityId: This is the entity id
				rotation: [x, y, z] position to be set
			*/
			let oldEntitiy = state.entityByIds[action.payload.entityId];
			oldEntitiy.rotation = action.payload.rotation;
			state.entityByIds[action.payload.id] = oldEntitiy;
		},
		setEntityQuaternion(state, action) {
			/*  
				entityId: This is the entity id
				quaternion: [x, y, z] position to be set
			*/
			let oldEntitiy = state.entityByIds[action.payload.entityId];
			oldEntitiy.quaternion = action.payload.quaternion;
			state.entityByIds[action.payload.id] = oldEntitiy;
		},
		setEntityName(state, action) {
			/*  
				entityId: This is the entity id
				name: name to be set
			*/
			let oldentity = state.entityByIds[action.payload.entityId];
			oldentity.name = action.payload.name;
			state.entityByIds[action.payload.entityId] = oldentity;
		},
		setEntityTransform(state, action) {
			/*  
				value: This is the value to be set
				entityId: This is the entity id
				type: position, rotation, scale
				axis: {0 : X}, {1 : Y}, {2 : Z}  
			*/
			let oldentity = state.entityByIds[action.payload.entityId];
			oldentity[action.payload.type][action.payload.axis] = action.payload.value;
			state.entityByIds[action.payload.entityId] = oldentity;
		},
		updateEntityTransformation(state, action) {
			/*  
				entityId: This is the entity id
				position: vec3
				rotation: vec3
				quaternion: quat
				scale: vec3
			*/
			state.entityByIds[action.payload.entityId].position = action.payload.transform.position;
			state.entityByIds[action.payload.entityId].rotation = action.payload.transform.rotation;
			state.entityByIds[action.payload.entityId].quaternion = action.payload.transform.quaternion;
			state.entityByIds[action.payload.entityId].scale = action.payload.transform.scale;

		},
		updateCameraComponentData(state, action) {
			let oldCameraComponent = state.componentByIds[action.payload.componentID];
			oldCameraComponent.data[action.payload.updateField] = action.payload.value;
			oldCameraComponent.needsUpdate = true;
			state.componentByIds[action.payload.componentID] = oldCameraComponent;
		},
		updateMaterialAssetData(state, action) {
			/*  
				assetId: This is the component id
				updateField: the field that needs to be updated {kind, clearCoat, clearCoatRoughness, reflectance, etc...}
				value: value to be set
			*/
			let oldMaterialAsset = state.assetByIds[action.payload.assetId];
			oldMaterialAsset.data[action.payload.updateField] = action.payload.value;
			state.assetByIds[action.payload.assetId] = oldMaterialAsset;
			state.componentByIds = findAndUpdateComponetWithUpdatedMaterial(state.componentByIds, action.payload.assetId);
		},
		updateLightComponentData(state, action) {//
			let oldLightComponet = state.componentByIds[action.payload.componentID];
			oldLightComponet.data[action.payload.updateField] = action.payload.value;
			oldLightComponet.needsUpdate = true;
			state.componentByIds[action.payload.componentID] = oldLightComponet
		},
		updateMeshComponentData(state, action) {//
			/*  
				componentId: This is the component id
				updateField: the field that needs to be updated {meshAssetId, glbMeshAssetId, materialAssetId, castShadows, receiveShadows}
				value: value to be set
			*/
			let oldmeshComponet = state.componentByIds[action.payload.componentId];
			oldmeshComponet.data[action.payload.updateField] = action.payload.value;
			oldmeshComponet.needsUpdate = true;
			state.componentByIds[action.payload.componentId] = oldmeshComponet
		},
		setGlobalHotspotSelectedMeshComponentForAnOption(state, action) {
			state.componentByIds[action.payload.componentId].data.targetMeshMaterialOptions[action.payload.optionId].targetMeshComponentId = action.payload.value;
		},
		updateUsdzComponentData(state, action) {//
			/*  
				componentId: This is the component id
				updateField: the field that needs to be updated {meshAssetId, materialAssetId, castShadows, receiveShadows}
				value: value to be set
			*/
			let oldUsdzComponet = state.componentByIds[action.payload.componentId];
			oldUsdzComponet.data[action.payload.updateField] = action.payload.value;
			oldUsdzComponet.needsUpdate = true;
			state.componentByIds[action.payload.componentId] = oldUsdzComponet
		},
		updateARComponentData(state, action) {
			/*
				componentId: This is the component id
				updateField: the field that needs to be updated {usdzAssetId, glbAssetId}
				value: value to be set
			*/
			let arComponet = state.componentByIds[action.payload.componentId];
			arComponet.data[action.payload.updateField] = action.payload.value;
			arComponet.needsUpdate = true;
		},
		updateAssetName(state, action) {
			/*
				assetId : Selected asset id
				name : name to be
			*/
			state.assetByIds[action.payload.assetId].name = action.payload.name;
		},
		updateSkyboxComponentData(state, action) {//
			state.componentByIds[action.payload.componentID].data[action.payload.updateField] = action.payload.value;
			state.componentByIds[action.payload.componentID].needsUpdate = true;
		},
		setMeshAssetIdForAnOptionInGlobalHotspot(state, action) {
			state.componentByIds[action.payload.hotspotComponentId].data.targetMeshMaterialOptions[action.payload.optionId].targetMeshAssetId = action.payload.choosedMeshAssetId;
		},
		setMaterialAssetIdForAnOptionInGlobalHotspot(state, action) {
			state.componentByIds[action.payload.hotspotComponentId].data.targetMeshMaterialOptions[action.payload.optionId].targetMaterialAssetId = action.payload.choosedMaterialAssetId;
		},
		addTargetOptionForGlobalHotspot(state, action) {
			state.componentByIds[action.payload.componentId].data.targetMeshMaterialOptions[action.payload.option.optionId] = action.payload.option;
		},
		/////// new fixes
		updateAsset(state, action) {
			state.assetByIds[action.payload.assetId].data[action.payload.property] = action.payload.value;
		},
		setComponentNeedsUpdate(state, action) {
			/*  
				componentId: This is the component id
				state: true or false
			*/
			state.componentByIds[action.payload.componentId].needsUpdate = action.payload.state;
		},
		removeAsset(state, action) {
			const assetIdToBeDeleted = action.payload.asset_id;
			const assetToBeDeleted = state.assetByIds[assetIdToBeDeleted];
			let selectedAssetPaths = [];
			// Push Deleted Asset Paths
			switch (assetToBeDeleted.type) {
				case OgmoConsts.AssetType.TEXTURE:
					selectedAssetPaths = assetToBeDeleted.data.originalTextures.map(asset => asset.split('/').pop());
				case OgmoConsts.AssetType.MESH:
					const meshPath = assetToBeDeleted.data.meshPath;
					if (meshPath) selectedAssetPaths = [meshPath.split('/').pop()];
				case OgmoConsts.AssetType.USDZ:
					const usdzPath = assetToBeDeleted.data.usdzPath;
					if (usdzPath) selectedAssetPaths = [usdzPath.split('/').pop()];
				case OgmoConsts.AssetType.GLB:
					const glbPath = assetToBeDeleted.data.glbPath;
					if (glbPath) selectedAssetPaths = [glbPath.split('/').pop()];
			}

			//Delete Asset Reference from Assets
			const referredAssetsIds = new Set();
			Object.keys(state.assetByIds).forEach(asset_id => {
				if (state.assetByIds[asset_id].type === OgmoConsts.AssetType.MATERIAL) {
					if (state.assetByIds[asset_id].data.albedoMap === assetIdToBeDeleted) {
						state.assetByIds[asset_id].data.albedoMap = '';
						state.assetByIds[asset_id].needsUpdate = true;
						referredAssetsIds.add(asset_id);
					}
					if (state.assetByIds[asset_id].data.normalMap === assetIdToBeDeleted) {
						state.assetByIds[asset_id].data.normalMap = '';
						state.assetByIds[asset_id].needsUpdate = true;
						referredAssetsIds.add(asset_id);
					}
					if (state.assetByIds[asset_id].data.aormMap === assetIdToBeDeleted) {
						state.assetByIds[asset_id].data.aormMap = '';
						state.assetByIds[asset_id].needsUpdate = true;
						referredAssetsIds.add(asset_id);
					}
				}
			})

			//Set needsUpdate flag from Reffered Asset's Components
			referredAssetsIds.forEach(asset_id => {
				Object.keys(state.componentByIds).forEach(component_id => {
					if (state.componentByIds[component_id].type === OgmoConsts.ComponentType.MESH) {
						if (state.componentByIds[component_id].data.meshAssetId === asset_id) {
							state.componentByIds[component_id].needsUpdate = true
						}
						if (state.componentByIds[component_id].data.materialAssetId === asset_id) {
							state.componentByIds[component_id].needsUpdate = true
						}
					}
				})
			})

			//Delete Asset Reference from Components
			Object.keys(state.componentByIds).forEach(component_id => {
				if (state.componentByIds[component_id].type === OgmoConsts.ComponentType.MESH) {
					if (state.componentByIds[component_id].data.meshAssetId === assetIdToBeDeleted) {
						state.componentByIds[component_id].data.meshAssetId = '';
						state.componentByIds[component_id].needsUpdate = true
					}
					if (state.componentByIds[component_id].data.materialAssetId === assetIdToBeDeleted) {
						state.componentByIds[component_id].data.materialAssetId = '';
						state.componentByIds[component_id].needsUpdate = true
					}
				}

				if (state.componentByIds[component_id].type === OgmoConsts.ComponentType.MESH_OPTION) {
					const { targetMeshOptions } = state.componentByIds[component_id].data;

					if (state.componentByIds[component_id].iconAssetId === assetIdToBeDeleted) {
						state.componentByIds[component_id].iconAssetId = '';
					}

					Object.keys(targetMeshOptions).forEach((key) => {
						if (targetMeshOptions[key].targetAssetId === assetIdToBeDeleted) {
							targetMeshOptions[key].targetAssetId = ""
						}
						if (targetMeshOptions[key].iconAssetId === assetIdToBeDeleted) {
							targetMeshOptions[key].iconAssetId = ""
						}
					}
					);
				}

				if (state.componentByIds[component_id].type === OgmoConsts.ComponentType.MATERIAL_OPTION) {
					const { targetMaterialOptions } = state.componentByIds[component_id].data;

					if (state.componentByIds[component_id].iconAssetId === assetIdToBeDeleted) {
						state.componentByIds[component_id].iconAssetId = '';
					}

					Object.keys(targetMaterialOptions).forEach((key) => {
						if (targetMaterialOptions[key].targetAssetId === assetIdToBeDeleted) {
							targetMaterialOptions[key].targetAssetId = ""
						}
						if (targetMaterialOptions[key].iconAssetId === assetIdToBeDeleted) {
							targetMaterialOptions[key].iconAssetId = ""
						}
					}
					);
				}

				if (state.componentByIds[component_id].type === OgmoConsts.ComponentType.GLOBAL_HOTSPOT) {
					const { targetMeshMaterialOptions } = state.componentByIds[component_id].data;

					if (state.componentByIds[component_id].data.iconAssetId === assetIdToBeDeleted) { //if the deleted asset is an icon
						state.componentByIds[component_id].data.iconAssetId = '';
					} else { //if the deleted asset is a mesh or material asset
						Object.keys(targetMeshMaterialOptions).forEach((key) => {
							if (targetMeshMaterialOptions[key].targetMeshAssetId === assetIdToBeDeleted) {
								targetMeshMaterialOptions[key].targetMeshAssetId = ""
							}
							if (targetMeshMaterialOptions[key].targetMaterialAssetId === assetIdToBeDeleted) {
								targetMeshMaterialOptions[key].targetMaterialAssetId = ""
							}
						}
						);
					}
				}

				if (state.componentByIds[component_id].type === OgmoConsts.ComponentType.GLOBAL_HOTSPOT_GROUP) {

					if (state.componentByIds[component_id].data.iconAssetId === assetIdToBeDeleted) {
						state.componentByIds[component_id].data.iconAssetId = '';
					}
				}

			})

			//Delete Asset From assetByIds
			delete state.assetByIds[assetIdToBeDeleted];
			state.selectedAssetId = "";
			state.selectedEntitiyId = state.rootId;
			state.assetIds = state.assetIds.filter(id => id !== assetIdToBeDeleted);
			state.deletedAssets.push(selectedAssetPaths)
		},
		emptyDeletedAssets(state, action) {
			state.deletedAssets = state.deletedAssets.filter(filename => filename !== action.payload.asset_filename)
		},
		removeEntity(state, action) {
			let childrenEntityIds = getAllChildrenIds(state.entityByIds,action.payload.entityId);
			const entityIdsToBeDeleted = [...childrenEntityIds,action.payload.entityId]

			// delete the entity from parent's children array
			let entityToDelete = state.entityByIds[action.payload.entityId];
			let parent_entity = state.entityByIds[entityToDelete.parent];
			parent_entity.children = parent_entity.children.filter(id => id != action.payload.entityId);

			entityIdsToBeDeleted.forEach(child_id => {
				// Delete the child
				delete state.entityByIds[child_id];
				state.entityIds = state.entityIds.filter(id => id != child_id);
			});
			state.selectedEntitiyId = (entityToDelete.parent) ? entityToDelete.parent : state.rootId;
			state.deletedEntityIds.push(entityIdsToBeDeleted);
		},
		emptyDeletedEntities(state, action) {
			state.deletedEntityIds = [];
		},
		editTargetMeshOptionComponentName(state, action) {
			state.componentByIds[action.payload.componentId].data.name = action.payload.globalMeshOptionComponentName
		},
		editTargetMaterialOptionComponentName(state, action) {
			state.componentByIds[action.payload.componentId].data.name = action.payload.globalMaterialOptionComponentName
		},
		setIconAssetToMeshOptionComponent(state, action) {
			const { globalMeshOptionComponentId, iconAssetId } = action.payload;
			state.componentByIds[globalMeshOptionComponentId].iconAssetId = iconAssetId;
		},
		setIconAssetToMaterialOptionComponent(state, action) {
			const { globalMaterialOptionComponentId, iconAssetId } = action.payload;
			state.componentByIds[globalMaterialOptionComponentId].iconAssetId = iconAssetId;
		},
		setIconToGlobalHotspotComponent(state, action) {
			const { globalHotspotComponentId, iconAssetId } = action.payload
			state.componentByIds[globalHotspotComponentId].data.iconAssetId = iconAssetId
		},
		/* 
		 * may be not the proper way. filter out the variants which are not the platform we gonna change. and push the new variants for that remaning array. 
		 * then assign the new array to redux. 
		 */ 
		setProductVariantToGlobalHotspotComponent(state, action) {
			const { globalHotspotComponentId, platform, variantId } = action.payload
			const haveVariantsLinkedBefore = state.componentByIds[globalHotspotComponentId].data["assignedProductVariants"]
			let updatedVariants = []
			if(haveVariantsLinkedBefore) {
			  updatedVariants = state.componentByIds[globalHotspotComponentId].data["assignedProductVariants"].filter(variant => variant.platform !== platform) 
			}
			updatedVariants.push({platform,variantId})
			state.componentByIds[globalHotspotComponentId].data["assignedProductVariants"] = updatedVariants
		},
		setMaterialOptionComponentColor(state, action) {
			const { componentID, value } = action.payload;
			state.componentByIds[componentID].color = value;
		},
		setGlobalHotspotColor(state, action) {
			let oldGlobalHotspotComponent = state.componentByIds[action.payload.componentId];
			oldGlobalHotspotComponent.data[action.payload.updateField] = action.payload.value;
			oldGlobalHotspotComponent.needsUpdate = true;
			state.componentByIds[action.payload.componentId]=oldGlobalHotspotComponent;
		},
		editGlobalHotspotName(state, action) {
			state.componentByIds[action.payload.componentId].data.displayName = action.payload.globalHotspotDisplayName
		},
		deleteTargetMeshMaterialOptionFromGlobalHotspot(state, action) {
			const { globalHotspotComponentId, meshMaterialOptionId } = action.payload
			delete state.componentByIds[globalHotspotComponentId].data.targetMeshMaterialOptions[meshMaterialOptionId]
		},
		deleteTargetMaterialAssetFromAnGlobalHotspotOption(state, action) {
			const { globalHotspotComponentId, meshMaterialOptionId} = action.payload
			delete state.componentByIds[globalHotspotComponentId].data.targetMeshMaterialOptions[meshMaterialOptionId].targetMaterialAssetId
		},
		deleteTargetMeshAssetFromAnGlobalHotspotOption(state, action) {
			const { globalHotspotComponentId, meshMaterialOptionId} = action.payload
			delete state.componentByIds[globalHotspotComponentId].data.targetMeshMaterialOptions[meshMaterialOptionId].targetMeshAssetId
		},
		editGlobalHotspotGroupName(state, action) {
			state.componentByIds[action.payload.componentId].data.displayName = action.payload.globalHotspotGroupDisplayName
		},
		setSelectedGlobalHotspotComponents(state, action){
			let globalHotspotGroupComponent = state.componentByIds[action.payload.componentId];
			globalHotspotGroupComponent.data[action.payload.updateField] = action.payload.value;
			globalHotspotGroupComponent.needsUpdate = true;
			state.componentByIds[action.payload.componentId]=globalHotspotGroupComponent;
		},
		handleDeleteSelectedGlobalHotspotFromAGlobalHotspotGroup(state, action){
			const { globalHotspotGroupComponentId, selectedGlobalHotspot} = action.payload
			let globalHotspotGroupComponent = state.componentByIds[globalHotspotGroupComponentId]
			let filteredGlobalHotspots = globalHotspotGroupComponent.data.selectedGlobalHotspots.filter(item => item !== selectedGlobalHotspot) 
			globalHotspotGroupComponent.data.selectedGlobalHotspots= filteredGlobalHotspots
			state.componentByIds[globalHotspotGroupComponentId] = globalHotspotGroupComponent
		},
		setIconToGlobalHotspotGroupComponent(state, action) {
			const { globalHotspotGroupComponentId, iconAssetId } = action.payload
			state.componentByIds[globalHotspotGroupComponentId].data.iconAssetId = iconAssetId
		},
		setGlobalHotspotGroupColor(state, action) {
			let globalHotspotGroupComponent = state.componentByIds[action.payload.componentId];
			globalHotspotGroupComponent.data[action.payload.updateField] = action.payload.value;
			globalHotspotGroupComponent.needsUpdate = true;
			state.componentByIds[action.payload.componentId]=globalHotspotGroupComponent;
		},
		setComponentIdentifierType(state, action) {
			let selectedComponent = state.componentByIds[action.payload.componentId];
			selectedComponent.data[action.payload.updateField] = action.payload.value;
			selectedComponent.needsUpdate = true;
			state.componentByIds[action.payload.componentId]=selectedComponent;
		}
	}
});
export const {
	resetScene,
	addAsset,
	setSelectedEntityId,
	setSelectedAssetId,
	addComponent,
	addComponentToEntity,
	removeComponent,
	addChildToEntity,
	addEntity,
	setEntityPosition,
	setEntityRotation,
	setEntityQuaternion,
	setEntityScale,
	setEntityName,
	setEntityTransform,
	updateEntityTransformation,
	updateCameraComponentData,
	updateMeshComponentData,
	setGlobalHotspotSelectedMeshComponentForAnOption,
	updateMaterialAssetData,
	updateLightComponentData,
	updateAssetName,
	updateSkyboxComponentData,
	setMeshAssetIdForAnOptionInGlobalHotspot,
	setMaterialAssetIdForAnOptionInGlobalHotspot,
	setProductVariantToGlobalHotspotComponent,
	addTargetOptionForGlobalHotspot,
	updateAsset,
	setComponentNeedsUpdate,
	updateUsdzComponentData,
	updateARComponentData,
	removeAsset,
	emptyDeletedAssets,
	removeEntity,
	emptyDeletedEntities,
	updateComponentName,
	editTargetMeshOptionComponentName,
	editTargetMaterialOptionComponentName,
	setIconAssetToMeshOptionComponent,
	setIconAssetToMaterialOptionComponent,
	setMaterialOptionComponentColor,
	editGlobalHotspotName,
	setIconToGlobalHotspotComponent,
	setGlobalHotspotColor,
	deleteTargetMeshMaterialOptionFromGlobalHotspot,
	deleteTargetMaterialAssetFromAnGlobalHotspotOption,
	deleteTargetMeshAssetFromAnGlobalHotspotOption,
	editGlobalHotspotGroupName,
	setSelectedGlobalHotspotComponents,
	handleDeleteSelectedGlobalHotspotFromAGlobalHotspotGroup,
	setIconToGlobalHotspotGroupComponent,
	setGlobalHotspotGroupColor,
	setComponentIdentifierType
} = sceneSlice.actions;

export const { reducer: sceneReducer } = sceneSlice
// export const sceneReducer;
