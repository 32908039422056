import { OgmoConsts } from '../constants/consts';
import { createUUID } from '../core/Utils';

export const GlobalHotspotGroupComponent = () => ({
    type: OgmoConsts.ComponentType.GLOBAL_HOTSPOT_GROUP,
    needsUpdate: true,
    id: createUUID(),
    name: "Global Hotspot Group",
    data: {
        displayName : "Default Group",
        identifierType: "",
        iconAssetId:"",
        color:[],
        selectedGlobalHotspots: []
    }
})