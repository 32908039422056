import { mat4, quat, vec3 } from "gl-matrix";
import { getEntityById } from "./useEntities";
import { hasComponent } from "./useComponets";
import { OgmoConsts } from "../constants/consts";
import { store } from '../reducer/storeCreator'
import { setEntityTransform, setEntityPosition, setEntityScale, setEntityRotation, setEntityQuaternion } from "../reducer/sceneReducer";

const state = {
	transformByIds: {},
	activeCameraId: ""
}

export const Transform = () => ({
	position: vec3.create(),
	rotation: vec3.create(),
	quaternion: quat.create(),
	scale: vec3.create(),
	matrix: mat4.create(),
	matrixWorld: mat4.create()
});

const setPosition = (id, position) => {
	const transform = state.transformByIds[id];
	if (transform) {
		vec3.copy(transform.position, position);
		calculateTransformationMatrix(id);
		updateWorldMatrices(id);

		store.dispatch(setEntityPosition({
			entityId: id,
			position: position
		}));
	}
}

const setPositionOnAxis = (id, value, axis) => { // axis - 0 : x, 1 : y, 2 : z
	const transform = state.transformByIds[id];
	if (transform) {
		transform.position[axis] = value;
		calculateTransformationMatrix(id);
		updateWorldMatrices(id);

		store.dispatch(setEntityTransform({
			value: value,
			entityId: id,
			type: "position",
			axis: axis
		}));
	}
}

const getPosition = (id) => {
	return vec3.clone(state.transformByIds[id].position);
}

const setRotation = (id, rotation) => {
	const transform = state.transformByIds[id];
	if (transform) {
		vec3.copy(transform.rotation, rotation);

		store.dispatch(setEntityRotation({
			entityId: id,
			rotation: rotation
		}));
	}
}

const setRotationOnAxis = (id, value, axis) => { // axis - 0 : x, 1 : y, 2 : z
	const transform = state.transformByIds[id];
	if (transform) {
		transform.rotation[axis] = value;

		store.dispatch(setEntityTransform({
			value: value,
			entityId: id,
			type: "rotation",
			axis: axis
		}));

		let quaternion = quat.fromEuler(quat.create(), transform.rotation[0], transform.rotation[1], transform.rotation[2]); 
		setQuaternion(id, quaternion);
	}
}

const setQuaternion = (id, quaternion) => {
	const transform = state.transformByIds[id];
	if (transform) {
		quat.copy(transform.quaternion, quaternion);
		calculateTransformationMatrix(id);
		updateWorldMatrices(id);

		store.dispatch(setEntityQuaternion({
			entityId: id,
			quaternion: [quaternion[0], quaternion[1], quaternion[2], quaternion[3]]
		}));
	}
}

const getRotation = (id) => {
	return vec3.clone(state.transformByIds[id].rotation);
}

const setScale = (id, scale) => {
	const transform = state.transformByIds[id];
	if (transform) {
		vec3.copy(transform.scale, scale);
		calculateTransformationMatrix(id);
		updateWorldMatrices(id);

		store.dispatch(setEntityScale({
			entityId: id,
			scale: scale
		}));
	}
}

const setScaleOnAxis = (id, value, axis) => { // axis - 0 : x, 1 : y, 2 : z
	const transform = state.transformByIds[id];
	if (transform) {
		transform.scale[axis] = value;
		calculateTransformationMatrix(id);
		updateWorldMatrices(id);

		store.dispatch(setEntityTransform({
			value: value,
			entityId: id,
			type: "scale",
			axis: axis
		}));
	}
}

const getScale = (id) => {
	return vec3.clone(state.transformByIds[id].scale);
}

const calculateTransformationMatrix = (id) => {
	const transform = state.transformByIds[id];
	if (transform) {
		mat4.fromRotationTranslationScale(transform.matrix, transform.quaternion, transform.position, transform.scale);
	}
}

const updateWorldMatrices = (id) => {
	const child = getEntityById(id);

	if (!child) return;

	const parent = getEntityById(child.parent);
	const child_transform = state.transformByIds[child.id];

	if (parent) {
		const parent_transform = state.transformByIds[parent.id];
		mat4.mul(child_transform.matrixWorld, parent_transform.matrixWorld, child_transform.matrix);
	} else {
		mat4.copy(child_transform.matrixWorld, child_transform.matrix);
	}

	child.children.forEach(childId => {
		updateWorldMatrices(childId);
	});
}

const addEntity = async (entity, parent) => {

	if (!entity) return;

	// This is done to prevent camera being reset at each update call.
	if (hasComponent(OgmoConsts.ComponentType.CAMERA, entity)) {

		if (!state.activeCameraId) {
			state.activeCameraId = entity.id;
		} else {
			return;
		}
	}

	const transform = Transform();

	vec3.copy(transform.position, entity.position);
	quat.copy(transform.quaternion, entity.quaternion);
	vec3.copy(transform.rotation, entity.rotation);
	vec3.copy(transform.scale, entity.scale);

	state.transformByIds[entity.id] = transform;
	calculateTransformationMatrix(entity.id);

	if (parent) {
		const parent_transform = state.transformByIds[parent.id];
		mat4.mul(transform.matrixWorld, parent_transform.matrixWorld, transform.matrix);
	} else {
		mat4.copy(transform.matrixWorld, transform.matrix);
	}
	return 1;
}

const addEntities = async () => {

	let state = store.getState();
	let updates = [];

	state.scene.entityIds.forEach(id => {
		let entity = getEntityById(id);
		let parent = getEntityById(entity.parent);
		updates = [...updates, addEntity(entity, parent)];
	})

	return updates;
}

const getTransform = (id) => {
	return state.transformByIds[id];
}

export { addEntities, getTransform, setPosition, getPosition, setRotation, setQuaternion, getRotation, setScale, getScale, setPositionOnAxis, setRotationOnAxis, setScaleOnAxis }



