export function createUUID() {
	var dt = new Date().getTime();
	var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
		var r = (dt + Math.random() * 16) % 16 | 0;
		dt = Math.floor(dt / 16);
		return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
	});
	return uuid;
}

export function calculateBoundingBox(vertices) {
	var minX = + Infinity;
	var minY = + Infinity;
	var minZ = + Infinity;

	var maxX = - Infinity;
	var maxY = - Infinity;
	var maxZ = - Infinity;

	for (var i = 0, l = vertices.length; i < l; i += 3) {

		var x = vertices[i];
		var y = vertices[i + 1];
		var z = vertices[i + 2];

		if (x < minX) minX = x;
		if (y < minY) minY = y;
		if (z < minZ) minZ = z;

		if (x > maxX) maxX = x;
		if (y > maxY) maxY = y;
		if (z > maxZ) maxZ = z;
	}

	return {
		min: [minX, minY, minZ],
		max: [maxX, maxY, maxZ]
	}
}