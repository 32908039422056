
import * as THREE from 'three';
import { OgmoConsts } from '../constants/consts';
import { getAssetById } from '../ecs/useAssets.js';
import { store } from '../reducer/storeCreator';

const OgmoCache = {

	textureCache: {},
	materialCache: {},

	getFromCacheOrWeb: async function (path){
		const loader = new THREE.TextureLoader();
		return new Promise(async (resolve, reject) => {
			var cache = this.textureCache[path];
			if (cache) {
				resolve(cache);
			} else {
				loader.load(path,
					(texture) => {
						this.textureCache[path] = texture;
						resolve(texture);
					}, (progress) => {
						//console.log(progress)
					}, (error) => {
						reject(error);
					}
				);
			}
		});
	},

	addMaterialToCache: async function (material_data) {
		let material = this.materialCache[material_data.id];

		if(!material || material.userData.kind != material_data.data.kind) {
			if(material_data.data.kind === OgmoConsts.MaterialType.LIT) {
				material = this.materialCache[material_data.id] = new THREE.MeshPhysicalMaterial({
					color: 0xFFFFFF
				});
				material.userData = {kind : OgmoConsts.MaterialType.LIT}
			} else if (material_data.data.kind === OgmoConsts.MaterialType.INVISIBLE_SHADOW) {
				material = this.materialCache[material_data.id] = new THREE.ShadowMaterial();
				material.userData = {kind : OgmoConsts.MaterialType.INVISIBLE_SHADOW}
			}
		}

		if (material_data.data.kind === OgmoConsts.MaterialType.LIT) {

			material.clearcoat = material_data.data.clearCoat;
			material.clearcoatRoughness = material_data.data.clearCoatRoughness;
			material.reflectivity = material_data.data.reflectance;
			material.name = material_data.name;

			const albedoTex = getAssetById(material_data.data.albedoMap);
			const normalTex = getAssetById(material_data.data.normalMap);
			const aormTex = getAssetById(material_data.data.aormMap);
			const aoTex = getAssetById(material_data.data.aoMap);
			const roughnessTex = getAssetById(material_data.data.roughnessMap);
			const metallicTex = getAssetById(material_data.data.metallicMap);

			if (albedoTex) {
				var albedo = await this.getFromCacheOrWeb(albedoTex.data.originalTextures[1]);
				material.map = albedo;
				material.map.encoding = THREE.LinearEncoding;
				material.color = new THREE.Color(0xFFFFFF);
			} else {
				material.map = null;
				material.color = new THREE.Color(material_data.data.albedoColor[0], material_data.data.albedoColor[1], material_data.data.albedoColor[2]);
			}

			material.metalness = material_data.data.metallicValue;
			material.roughness = material_data.data.roughnessValue;

			if (normalTex) {
				var normal = await this.getFromCacheOrWeb(normalTex.data.originalTextures[1]);
				material.normalMap = normal;
			} else {
				material.normalMap = null;
			}

			if (aormTex) {
				var aorm = await OgmoCache.getFromCacheOrWeb(aormTex.data.originalTextures[1]);
				material.aoMap = aorm;
				material.aoMap.encoding = THREE.LinearEncoding;
				material.roughnessMap = aorm;
				material.roughnessMap.encoding = THREE.LinearEncoding;
				material.metalnessMap = aorm;
				material.metalnessMap.encoding = THREE.LinearEncoding;
			} else {
				if (metallicTex) {
					var metallicMap = await OgmoCache.getFromCacheOrWeb(metallicTex.data.originalTextures[1]);
					material.metalnessMap = metallicMap;
					material.metalnessMap.encoding = THREE.LinearEncoding;
				} else {
					material.metalnessMap = null;
				}

				if (roughnessTex) {
					var roughnessMap = await OgmoCache.getFromCacheOrWeb(roughnessTex.data.originalTextures[1]);
					material.roughnessMap = roughnessMap;
					material.roughnessMap.encoding = THREE.LinearEncoding;
				} else {
					material.roughnessMap = null;
				}

				if (aoTex) {
					var aoMap = await OgmoCache.getFromCacheOrWeb(aoTex.data.originalTextures[1]);
					material.aoMap = aoMap;
					material.aoMap.encoding = THREE.LinearEncoding;
				} else {
					material.aoMap = null;
				}
			}
			if(material_data.data.transparent) {
				material.transparent = true;
				material.opacity = material_data.data.opacity;
			} else {
				material.transparent = false;
				material.opacity = 1.0;
			}
		}
		else if (material_data.data.kind === OgmoConsts.MaterialType.INVISIBLE_SHADOW) {
			material.opacity = material_data.data.opacity ? material_data.data.opacity : 0.2;
		}
		material.needsUpdate = true;
		return 1;
	},

	getMaterial : function (material_asset_id) {

		let material = this.materialCache[material_asset_id];
		if(!material) {
			material  = new THREE.MeshPhysicalMaterial({
				color: 0xFFFFFF
			});
		}

		return material;
	}
}

export { OgmoCache }