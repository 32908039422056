import React, { useEffect, useMemo } from "react";
import "./GlobalHotspotSection.css";
import { useSelector, useDispatch } from "react-redux";
import { OgmoConsts } from "ogmo_core";
import panelOpenCloseButtonMobile from '../../images/upmobile.png';
import panelCloseButtonMobile from '../../images/drop_mobile1.png'
import panelOpenCloseButtonDesktop from '../../images/panelCloseButtonDesktop.png';
import panelCloseButtonDesktop from '../../images/panelCloseDesktop.png'
import ARButton from '../ARButton/ARButton';
import MobileHotspotPanelContent from '../MobileHotspotPanelContent/MobileHotspotPanelContent'
import DesktopHotspotPanelContent from '../DesktopHotspotPanelContent/DesktopHotspotPanelContent'
import AddToCartButton from "../AddToCartButton"

const MobileHotspotPanel = (
  { isOpenedHotspotPanel,
    setIsOpenedHotspotPanel,
    shopifyStoreURL,
    globalHotspotGroups }) => {

  useEffect(() => {
    setIsOpenedHotspotPanel(false);
  }, [])

  const togglePanelState = () => {
    setIsOpenedHotspotPanel(!isOpenedHotspotPanel)
    if (!isOpenedHotspotPanel) {
      document.getElementById("panelMobile").style.height = "250px";
    }
    else {
      document.getElementById("panelMobile").style.height = "35px";
    }
  }

  if (globalHotspotGroups.length === 0) {
    return (
      <div className="arButtonWithoutHotspotsMobile">
        <ARButton />
      </div>
    );
  }

  return (
    <div className="globalHotspotSectionContent">
      <div className="arButtonWithHotspots">
        {shopifyStoreURL && <AddToCartButton platform="shopify" siteURL={shopifyStoreURL} />}
        <div style={{flexGrow: 1}}></div>
        <ARButton />
      </div>
      <img src={(isOpenedHotspotPanel ? panelCloseButtonMobile : panelOpenCloseButtonMobile)} alt="back"
        className={(isOpenedHotspotPanel ? "closeButtonImageMobile" : "openButtonImageMobile")}
        onClick={() => togglePanelState()}
      />
      <div id="panelMobile" className="globalHotspotPanelMobile">
        <MobileHotspotPanelContent />
      </div>
    </div>
  )
}

const DesktopHotspotPanel = ({ isOpenedHotspotPanel, setIsOpenedHotspotPanel, shopifyStoreURL, globalHotspotGroups }) => {

  useEffect(() => {
    setIsOpenedHotspotPanel(false);
  }, [])

  const togglePanelState = () => {
    setIsOpenedHotspotPanel(!isOpenedHotspotPanel)
    if (!isOpenedHotspotPanel) {
      document.getElementById("panelDesktop").style.width = "350px";
    }
    else {
      document.getElementById("panelDesktop").style.width = "0px";
    }
  }

  if (globalHotspotGroups.length === 0) {
    return (
      <div className="arButtonWithoutHotspotsDesktop">
        <ARButton />
      </div>
    );
  }

  return(
    <div>
      <div>
        <img src={(isOpenedHotspotPanel ? panelCloseButtonDesktop : panelOpenCloseButtonDesktop)} alt="back"
          className={(isOpenedHotspotPanel ? "closeButtonImageDesktop" : "openButtonImageDesktop")}
          onClick={() => togglePanelState()}
        />
        <div id="panelDesktop" className="globalHotspotPanelDesktop">
          <DesktopHotspotPanelContent />
        </div>
      </div>
      <div className="arButtonHolderDesktop">
        <div style={{marginRight: "auto",width: "100vw"}}>
        {shopifyStoreURL && <AddToCartButton platform="shopify" siteURL={shopifyStoreURL} />} 
        <ARButton />
        </div>
      </div>
  </div>
  )
}


export default function GlobalHotSpotSection({ isOpenedHotspotPanel, setIsOpenedHotspotPanel }) {
  const dispatch = useDispatch();
  const ents = useSelector(state => state.scene.entityByIds);
  const isIPhoneDevice = /iPhone/g.test(navigator.userAgent);
  const isAndroidDevice = /android/i.test(navigator.userAgent);
  const isIPadDevice = /iPad/.test(navigator.userAgent) || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)

  let globalHotspotGroups = [];
  Object.keys(ents).forEach(entityId => {
    ents[entityId].components.forEach(component => {
      if (component.type === OgmoConsts.ComponentType.GLOBAL_HOTSPOT_GROUP) {
        globalHotspotGroups.push(component.id)
      }
    })
  })

  const shopifyStoreURL = useMemo(() => {
    const params = new URLSearchParams(window.location.search)
    const shopifyStore = params.get("shopifyStore")
    return shopifyStore ? shopifyStore : "" 
  },[])

  return (
    <>
      {(isIPhoneDevice || isAndroidDevice || isIPadDevice) ?
        <MobileHotspotPanel
          isOpenedHotspotPanel={isOpenedHotspotPanel}
          setIsOpenedHotspotPanel={setIsOpenedHotspotPanel}
          shopifyStoreURL={shopifyStoreURL}
          globalHotspotGroups={globalHotspotGroups}
        /> :
        <DesktopHotspotPanel
          isOpenedHotspotPanel={isOpenedHotspotPanel}
          setIsOpenedHotspotPanel={setIsOpenedHotspotPanel}
          shopifyStoreURL={shopifyStoreURL}
          globalHotspotGroups={globalHotspotGroups}
        />
      }
    </>
  );
}
